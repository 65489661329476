import { useAntdTable, useRequest } from 'ahooks';
import { addUser, listSystemUser, updateUser } from '../../apis/api';
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Form,
  Input,
  InputNumber,
  MenuProps,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tag,
  Tooltip,
  Upload,
  message,
} from 'antd';
import { useCompany } from '../../hooks/company';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import RoleSelect from '../../components/RoleSelect';
import DepartmentSelect from '../../components/DepartmentSelect';
import {
  createContext,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
  findAllDepartmentId,
  findChildrenDepartment,
  flatDepartment,
} from '../../utils';
import {
  ListUserItemDTO,
  UserCountAllNewCustomerLimitResponse,
  UserDTO,
  companyController,
  userController,
} from '../../api';
import '../../styles/common.scss';
import OSSImage, { AvatarImg } from '../../components/OSSImage';
import UserTransfer from '../../components/UserTransfer';

const context = createContext<{
  refresh?: (reset?: false) => void;
  setEditUserConfigUserId: (id: I.ID) => void;
  totalLimit?: UserCountAllNewCustomerLimitResponse;
}>({
  setEditUserConfigUserId(id) {},
});

const UserTotalNewCustomerLimit = () => {
  const { totalLimit } = useContext(context);
  return (
    <Tooltip title="全部人员接单上限（总共/有效）">
      {totalLimit?.count}/{totalLimit?.countAvailable}
    </Tooltip>
  );
};

const UpdateUserConfig = ({
  userId,
  open,
  onCancel,
  refresh,
}: {
  userId?: I.ID;
  open?: boolean;
  onCancel: () => void;
  refresh: () => void;
}) => {
  const { id: companyId } = useCompany();

  const [form] = Form.useForm();

  const { data: userConfig, loading } = useRequest(
    () => {
      return userController.getUserConfig(companyId, userId!);
    },
    {
      ready: !!userId,
      refreshDeps: [userId, companyId],
    },
  );

  const { runAsync: updateConfig, loading: updateLoading } = useRequest(
    (config: Parameters<typeof userController.updateUserConfig>[2]) => {
      return userController.updateUserConfig(companyId, userId!, config);
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    form.resetFields();
  }, [open, userConfig]);

  const onSubmit = () => {
    return form
      .validateFields()
      .then((values) => {
        return updateConfig({
          ...userConfig,
          ...values,
        }).then(() => {
          onCancel();
          refresh();
          message.success(`修改成功`);
        });
      })
      .catch((e) => {});
  };

  return (
    <Modal
      title="修改配置"
      open={open}
      onCancel={onCancel}
      confirmLoading={loading || updateLoading}
      onOk={onSubmit}
    >
      <Spin spinning={loading}>
        <Form form={form} initialValues={{ ...userConfig }}>
          <Form.Item
            name="newCustomerLimit"
            label="接单上限"
            rules={[{ required: true }]}
          >
            <InputNumber disabled={updateLoading || loading} />
          </Form.Item>
          <Form.Item
            name="newCustomerStatus"
            label="暂停接单"
            valuePropName="checked"
          >
            <Switch loading={updateLoading || loading} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

const ResetPassword = ({
  user,
  open,
  onCancel,
}: {
  user: UserDTO;
  open?: boolean;
  onCancel: () => void;
}) => {
  const { id: companyId } = useCompany();
  const { loading, run } = useRequest(
    () => {
      return userController.resetPassword(companyId, user.id);
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('重置成功');
        onCancel();
      },
    },
  );
  return (
    <Modal
      title="确认重置密码"
      open={open}
      onCancel={onCancel}
      confirmLoading={loading}
      onOk={() => {
        run();
      }}
    />
  );
};

const UpdateUser = ({
  user,
  open,
  onCancel,
}: {
  user: ListUserItemDTO;
  open?: boolean;
  onCancel: () => void;
}) => {
  const [form] = Form.useForm();

  const { id: companyId, currentUser, hasPermission } = useCompany();
  const { refresh } = useContext(context);

  const { loading, runAsync } = useRequest(
    (values) => {
      return updateUser(companyId, user.id, values);
    },
    {
      manual: true,
      refreshDeps: [companyId, user],
    },
  );

  const onSubmit = () => {
    return form
      .validateFields()
      .then((values) => {
        return runAsync(values)
          .then(() => {
            message.success('修改成功');
            refresh?.();
            onCancel();
          })
          .catch((e) => {
            message.error(`修改失败：${e.message}`);
          });
      })
      .catch((e) => {});
  };

  return (
    <Modal
      title="修改员工信息"
      open={open}
      onCancel={onCancel}
      onOk={onSubmit}
      okButtonProps={{
        disabled: !currentUser.isSuperAdmin && currentUser.id === user.id,
      }} // 非超级管理员不能修改自己的信息
      confirmLoading={loading}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        autoComplete="off"
        initialValues={{
          name: user.name,
          mobile: user.mobile,
          roleId: user.roleId,
          departmentId: user.departmentId,
          state: user.state,
        }}
      >
        <Form.Item name="name" label="姓名" rules={[{ required: true }]}>
          <Input readOnly={!(
          currentUser.isSuperAdmin ||
          hasPermission('page:system:user:update:name')
        )} />
        </Form.Item>
        <Form.Item name="mobile" label="手机号码" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="roleId" label="角色" rules={[{ required: true }]}>
          <RoleSelect />
        </Form.Item>
        <Form.Item name="departmentId" label="区域">
          <DepartmentSelect />
        </Form.Item>
        <Form.Item name="state" label="状态">
          <Radio.Group>
            <Radio value="ACTIVE">激活</Radio>
            <Radio value="INACTIVE">禁用</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const IDCardModal = ({
  userId,
  userName,
  open,
  onCancel,
}: {
  userId: I.ID;
  userName: string;
  open?: boolean;
  onCancel: () => void;
}) => {
  const { id: companyId } = useCompany();

  const {
    data: userConfig,
    loading: getLoading,
    refresh,
    run,
  } = useRequest(
    () => {
      return userController.getUserConfig(companyId, userId);
    },
    {
      manual: true,
    },
  );

  const [reUpload, setReUpload] = useState<number>(0);

  const [form] = Form.useForm();

  const { run: runAsync, loading: uploadLoading } = useRequest(
    userController.updateIdCard,
    {
      manual: true,
      onSuccess: () => {
        message.success('上传成功');
        form.resetFields();
        setReUpload((v) => v + 1);
        refresh();
      },
      onError: (e) => {
        message.error(`上传失败：${e.message}`);
      },
    },
  );

  const loading = getLoading || uploadLoading;

  useEffect(() => {
    if (open) {
      run();
      form.resetFields();
      setReUpload(0);
    }
  }, [open, run, form, setReUpload]);

  const onSubmit = () => {
    return form
      .validateFields()
      .then((values) => {
        return runAsync(
          companyId,
          userId,
          values.frontend.file,
          values.backend.file,
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Modal
      open={open}
      title={`${userName} 身份证信息`}
      onCancel={onCancel}
      confirmLoading={loading}
      onOk={onSubmit}
    >
      {userConfig?.idCard && (
        <Row>
          <Col span={12}>
            <OSSImage
              path={`useridCard/${userId}/frontend`}
              reload={reUpload}
            />
          </Col>
          <Col span={12}>
            <OSSImage path={`useridCard/${userId}/backend`} reload={reUpload} />
          </Col>
        </Row>
      )}
      <Form form={form}>
        <Row>
          <Col span={12}>
            <Form.Item
              name="frontend"
              valuePropName="valueList"
              rules={[{ required: true }]}
            >
              <Upload
                beforeUpload={(file) => false}
                accept="image/*"
                multiple={false}
                maxCount={1}
                name="avatar"
                listType="picture-card"
                showUploadList={true}
                // beforeUpload={beforeUpload}
                // onChange={handleChange}
              >
                <div>
                  {loading ? <LoadingOutlined /> : <PlusOutlined />}
                  <div style={{ marginTop: 8 }}>正面</div>
                </div>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="backend"
              valuePropName="valueList"
              rules={[{ required: true }]}
            >
              <Upload
                beforeUpload={(file) => false}
                accept="image/*"
                multiple={false}
                maxCount={1}
                name="avatar"
                listType="picture-card"
                showUploadList={true}
                // beforeUpload={beforeUpload}
                // onChange={handleChange}
              >
                <div>
                  {loading ? <LoadingOutlined /> : <PlusOutlined />}
                  <div style={{ marginTop: 8 }}>反面</div>
                </div>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const UserOperator = ({ user }: { user: ListUserItemDTO }) => {
  const { id: companyId, hasPermission, currentUser } = useCompany();

  const { refresh, setEditUserConfigUserId } = useContext(context);

  const [open, setOpen] = useState<boolean>();
  const [resetPasswordOpen, setRestPasswordOpen] = useState<boolean>();

  const [transferOpen, setTransferOpen] = useState<boolean>();

  const [idCardOpen, setIdCardOpen] = useState<boolean>();

  const { loading: removeLoading, runAsync: removeUser } = useRequest(
    () => {
      return updateUser(companyId, user.id, {
        ...user,
        state: 'DELETED',
      });
    },
    {
      manual: true,
    },
  );

  const [modal, contextHolder] = Modal.useModal();

  const { loading: switchLoading, runAsync: switchUser } = useRequest(
    (userId: I.ID) => {
      return userController.switchUser(companyId, userId);
    },
    {
      manual: true,
      onSuccess: (resp) => {
        const qs = new URLSearchParams({
          token: resp.token,
          companyId: companyId.toString(),
        });
        window.open(`/switch-user?${qs.toString()}`, '_blank');
      },
    },
  );

  const { runAsync: setLoginWithSMS } = useRequest(
    () => {
      return userController.updateUserConfig(companyId, user.id, {
        loginWithoutSMS: !user.loginWithoutSMS,
      });
    },
    { manual: true },
  );

  const { runAsync: switchVacation } = useRequest(
    () => {
      return userController.switchVacation(companyId, user.id, !user.vacation);
    },
    { manual: true },
  );

  const { runAsync: switchNewCustomer } = useRequest(
    () => {
      return userController.updateUserConfig(companyId, user.id, {
        ...user,
        newCustomerStatus: !user.newCustomerStatus,
      });
    },
    {
      manual: true,
    },
  );

  const items: (NonNullable<MenuProps['items']>[0] & { hide?: boolean })[] =
    useMemo(() => {
      const result = [
        {
          label: '身份证',
          key: 'idcard',
          hide: !hasPermission('page:system:user:upload-id-card'),
          disabled: currentUser.id === user.id,
          onClick: () => {
            setIdCardOpen(true);
          },
        },
        {
          label: '修改',
          key: 'update',
          hide: !hasPermission('page:system:user:edit'),
          disabled: currentUser.id === user.id,
          onClick: () => {
            setOpen(true);
          },
        },
        {
          label: '删除',
          key: 'remove',
          hide: !hasPermission('page:system:user:delete'),
          disabled: currentUser.id === user.id,
          danger: true,
          onClick: () => {
            return removeUser()
              .then(() => {
                message.success('删除成功');
                refresh?.(false);
              })
              .catch((e) => message.error(`删除用户失败：${e.message}`));
          },
        },
        {
          label: '接单上限',
          key: 'userConfigLimit',
          hide: !hasPermission('page:system:user:newCustomerLimit'),
          onClick: () => {
            setEditUserConfigUserId(user.id);
          },
        },
        {
          label: user.newCustomerStatus ? '开启接单' : '暂停接单',
          key: 'switch-new-customer',
          hide: !hasPermission('page:system:user:newCustomerPause'),
          onClick: () => {
            modal.confirm({
              title: user.newCustomerStatus ? '开启接单' : '暂停接单',
              onOk: () => {
                return switchNewCustomer()
                  .then(() => {
                    message.success('设置成功');
                    refresh?.(false);
                  })
                  .catch((e) => {
                    message.error(`设置失败：${e.message}`);
                  });
              },
            });
          },
        },
        {
          label: '切换用户',
          key: 'switch',
          hide: !hasPermission('page:system:user:switch-user'),
          onClick: () => {
            return switchUser(user.id);
          },
        },
        // {
        //   label: '转移',
        //   key: 'transfer',
        //   hide: !currentUser.isSuperAdmin,
        //   onClick: () => {
        //     setTransferOpen(true);
        //   },
        // },
        {
          label: '重置密码',
          key: 'resetPassword',
          onClick: () => {
            return setRestPasswordOpen(true);
          },
        },
        {
          label: user.vacation ? '取消休假' : '设置休假',
          key: 'switch-vacation',
          hide: !hasPermission('page:system:user:switch-vacation'),
          onClick: () => {
            modal.confirm({
              title: user.vacation ? '取消休假' : '设置休假',
              onOk: () => {
                return switchVacation()
                  .then(() => {
                    message.success('设置成功');
                    refresh?.(false);
                  })
                  .catch((e) => {
                    message.error(`设置失败：${e.message}`);
                  });
              },
            });
          },
        },
        {
          label: user.loginWithoutSMS ? '取消免短信登录' : '设置免短信登录',
          key: 'login-without-sms',
          hide: !hasPermission('page:system:user:loginWithoutSMS'),
          onClick: () => {
            modal.confirm({
              title: user.loginWithoutSMS ? '取消免短信登录' : '设置免短信登录',
              onOk: () => {
                return setLoginWithSMS()
                  .then(() => {
                    message.success('设置成功');
                    refresh?.(false);
                  })
                  .catch((e) => {
                    message.error(`设置失败：${e.message}`);
                  });
              },
            });
          },
        },
      ];
      return result
        .filter(({ hide }) => !hide)
        .map((item) => ({ ...item, hide: undefined }));
    }, [
      hasPermission,
      refresh,
      removeUser,
      setEditUserConfigUserId,
      switchUser,
      switchVacation,
      setLoginWithSMS,
      user,
      currentUser,
    ]);

  const loading = switchLoading || removeLoading;

  return (
    <>
      <>{contextHolder}</>
      {items.length ? (
        <Dropdown
          menu={{
            items,
          }}
        >
          <Button loading={loading}>
            <Space>
              操作
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ) : (
        '-'
      )}
      <UpdateUser user={user} open={open} onCancel={() => setOpen(false)} />
      <ResetPassword
        user={user}
        open={resetPasswordOpen}
        onCancel={() => setRestPasswordOpen(false)}
      />
      <IDCardModal
        open={idCardOpen}
        userId={user.id}
        userName={user.name}
        onCancel={() => setIdCardOpen(false)}
      />
      <UserTransfer
        open={transferOpen}
        onCancel={() => setTransferOpen(false)}
        id={user.id}
        name={user.name}
      />
    </>
  );
};

const AddUser = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [form] = Form.useForm();
  const {
    id: companyId,
    hasPermission,
    currentUser,
    rootDepartmentId,
    departments,
  } = useCompany();
  const { refresh, setEditUserConfigUserId } = useContext(context);

  const ignoreCompany = Form.useWatch<boolean>('ignoreCompany', form);

  console.log(ignoreCompany);

  const { loading, runAsync } = useRequest(addUser, {
    refreshDeps: [companyId],
    manual: true,
    onError: (e) => {
      message.error(e.message);
    },
    onSuccess: () => {
      setOpen(false);
      refresh?.();
      message.success('添加用户成功');
    },
  });

  const departmentId = useMemo(() => {
    if (!rootDepartmentId) {
      return undefined;
    }

    const children = departments.filter(
      (item) => rootDepartmentId === item.parentId,
    );
    if (children.length) {
      return undefined;
    }
    // 没有子区域，就选择自己当前
    return rootDepartmentId;
  }, [rootDepartmentId, departments]);

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
  }, [open, form]);

  const onSubmit = () => {
    return form
      .validateFields()
      .then((values) => {
        return runAsync(companyId, {
          ...values,
          departmentId: values.ignoreCompany ? undefined : values.departmentId,
        });
      })
      .catch((e) => {});
  };
  return (
    <>
      <Modal
        title="新增员工"
        open={open}
        confirmLoading={loading}
        onCancel={() => setOpen(false)}
        onOk={onSubmit}
        destroyOnClose
      >
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          initialValues={{
            departmentId,
          }}
          autoComplete="off"
        >
          <Form.Item name="name" label="姓名" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="mobile"
            label="手机号码"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="roleId" label="角色" rules={[{ required: true }]}>
            <RoleSelect />
          </Form.Item>
          <Form.Item
            name="ignoreCompany"
            label="集团直属"
            hidden={!currentUser.isSuperAdmin}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item name="departmentId" label="区域">
            <DepartmentSelect disabled={ignoreCompany} />
          </Form.Item>
        </Form>
      </Modal>
      {hasPermission('page:system:user:add-user') && (
        <Button type="primary" onClick={() => setOpen(true)}>
          新增
        </Button>
      )}
    </>
  );
};

const columns: ColumnsType<ListUserItemDTO> = [
  {
    title: 'id',
    dataIndex: 'id',
    render: (_, { id, state }) => {
      return (
        <Space>
          <span>{id}</span>
          {state !== 'ACTIVE' && <Tag color="red">{state}</Tag>}
        </Space>
      );
    },
  },
  {
    title: '账号',
    render: (_, user) => {
      const { roles } = useCompany();
      return (
        <Space>
          <AvatarImg userId={user.id} />
          <span>{user.name}</span>
          <span>（{user.mobile}）</span>
          <Tag>{roles?.find((role) => role.id === user.roleId)?.name}</Tag>
        </Space>
      );
    },
  },
  {
    title: '区域',
    render: (_, { departmentId }) => {
      const { departments } = useCompany();
      const deps = flatDepartment(departments, departmentId).reverse();
      return deps.map((department) => department.name).join('/') || '-';
    },
  },
  {
    title: <UserTotalNewCustomerLimit />,
    render: (_, user) => {
      return (
        <span style={{ maxWidth: '150px' }}>
          {user.loginWithoutSMS && <Tag color="red">免短信登录</Tag>}
          {user.newCustomerStatus && (
            <Tooltip title={user.selfPause && '员工主动暂停'}>
              <Tag color={user.selfPause ? 'red' : undefined}>暂停接单</Tag>
            </Tooltip>
          )}
          {user.vacation && (
            <Tooltip title="休假人员的客户不会被抓入公共池">
              <Tag>休假</Tag>
            </Tooltip>
          )}
          <Tag>日接单上限：{user.newCustomerLimit || 0}</Tag>
        </span>
      );
    },
  },
  {
    title: '创建时间',
    dataIndex: 'createdAt',
    render: (createdAt) => dayjs(createdAt).format('YYYY-MM-DD'),
  },
  {
    title: '最近登录',
    render: (_, dto) => {
      return (
        <Space direction="vertical" wrap size={0}>
          <span>{dto.lastIP || '-'}</span>
          <span>{dto.lastIPAddress || '-'}</span>
          <span>{dto.lastLoginAt && dayjs(dto.lastLoginAt).fromNow()}</span>
        </Space>
      );
    },
  },
  {
    title: <AddUser />,
    render: (_, user) => {
      const { roles } = useCompany();
      const role = roles?.find((item) => item.id === user.roleId);
      return <UserOperator user={user} />;
    },
  },
];

export default () => {
  const {
    id: companyId,
    departments,
    currentUser,
    hasPermission,
  } = useCompany();

  const [form] = Form.useForm<{ name: string; departmentId: I.ID }>();

  const depId = Form.useWatch('departmentId', form);

  const {
    data: totalLimit,
    loading: limitLoading,
    refresh: refreshLimit,
  } = useRequest(
    () => {
      return form.validateFields().then((values) => {
        const rootDepartmentId = depId ? depId : currentUser.departmentId;

        const deps = rootDepartmentId
          ? findChildrenDepartment(departments, rootDepartmentId).map(
              (item) => item.id,
            )
          : undefined;
        if (rootDepartmentId) {
          deps?.push(rootDepartmentId);
        }
        return userController.countAllUserNewCustomerLimitV2(companyId, {
          companyId,
          ...values,
          departmentIds: deps,
        });
      });
    },
    {
      ready: !!companyId,
      refreshDeps: [companyId, currentUser, form, depId],
    },
  );

  const [editUserConfigUserId, setEditUserConfigUserId] = useState<I.ID>();

  const { tableProps, search, error, refresh } = useAntdTable(
    ({ current, pageSize }, formData) => {
      const rootDepartmentId = formData.departmentId
        ? formData.departmentId
        : currentUser.departmentId;

      const deps = rootDepartmentId
        ? findChildrenDepartment(departments, rootDepartmentId).map(
            (item) => item.id,
          )
        : undefined;
      if (rootDepartmentId) {
        deps?.push(rootDepartmentId);
      }
      return userController
        .listUser(companyId, current, pageSize, {
          ...formData,
          departmentIds: deps,
          vacation: formData.vacation ? true : undefined,
          loginWithoutSMS: formData.loginWithoutSMS ? true : undefined,
        })
        .then((resp) => {
          return {
            list: resp.list || [],
            total: resp.total || 0,
          };
        });
    },
    {
      form,
      refreshDeps: [companyId, currentUser, departments],
    },
  );

  if (error) {
    message.error(error.message || '未知错误');
  }

  const { submit } = search;

  return (
    <div>
      <Row justify={'space-between'}>
        <Col>
          <Form
            form={form}
            initialValues={{ userStates: 'ACTIVE' }}
            style={{ display: 'flex', justifyContent: 'flex-start', gap: 5 }}
          >
            <Form.Item name="name">
              <Input.Search
                placeholder="姓名"
                style={{ width: 140 }}
                onSearch={submit}
              />
            </Form.Item>
            <Form.Item name="departmentId">
              <DepartmentSelect
                style={{ width: 240 }}
                onSelect={submit}
                onClear={submit}
              />
            </Form.Item>
            <Form.Item
              name="roleId"
              hidden={!hasPermission('page:system:user:search:role')}
            >
              <RoleSelect
                onSelect={submit}
                style={{ width: 140 }}
                onClear={submit}
                allowClear
              />
            </Form.Item>
            <Form.Item
              name="userStates"
              hidden={!hasPermission('page:system:user:search:state')}
            >
              <Select style={{ width: 100 }} onSelect={submit}>
                <Select.Option value="ACTIVE">激活</Select.Option>
                <Select.Option value="INACTIVE">禁用</Select.Option>
                <Select.Option value="DELETED">删除</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="vacation" valuePropName="checked">
              <Checkbox onChange={submit}>休假人员</Checkbox>
            </Form.Item>
            <Form.Item name="loginWithoutSMS" valuePropName="checked">
              <Checkbox onChange={submit}>免短信登录</Checkbox>
            </Form.Item>
            <Form.Item
              name="ignoreCompany"
              valuePropName="checked"
              hidden={!currentUser.isSuperAdmin}
            >
              <Checkbox onChange={submit}>集团直属</Checkbox>
            </Form.Item>
          </Form>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              submit();
              refreshLimit();
            }}
          >
            查询
          </Button>
        </Col>
      </Row>
      <UpdateUserConfig
        userId={editUserConfigUserId}
        open={!!editUserConfigUserId}
        refresh={() => {
          refresh();
          refreshLimit();
        }}
        onCancel={() => {
          setEditUserConfigUserId(undefined);
        }}
      />
      <context.Provider
        value={{
          refresh: () => {
            refresh();
            refreshLimit();
          },
          totalLimit,
          setEditUserConfigUserId,
        }}
      >
        <Table
          className="normal-table"
          columns={columns}
          rowKey="id"
          {...tableProps}
          pagination={{
            ...tableProps.pagination,
            showSizeChanger: true,
          }}
          bordered
          size="small"
        />
      </context.Provider>
    </div>
  );
};
