/* 该文件由 Nad CLI 生成，请勿手改 */
/* This file is generated by Nad CLI, do not edit manually. */
/* eslint-disable */

import { NadInvoker } from '@huolala-tech/nad-runtime';
import type { Settings } from '@huolala-tech/nad-runtime';

export class Runtime<T = unknown> extends NadInvoker<T> {
  public static base = 'http://localhost:8080';
}

/**
 * loginController
 * @iface com.weiyirong99.rms.api.controller.LoginController
 */
export const loginController = {
  /**
   * getSystemConfig
   */
  async getSystemConfig(ip?: string, settings?: Partial<Settings>) {
    return new Runtime<SystemConfigModel>()
      .open('GET', '/user/system/config', settings)
      .addRequestParam('ip', ip)
      .execute();
  },
  /**
   * getUser
   */
  async getUser(cookie?: string, headerToken?: string, settings?: Partial<Settings>) {
    return new Runtime<CurrentUserDTO>()
      .open('GET', '/user/current', settings)
      .addRequestParam('cookie', cookie)
      .addRequestParam('headerToken', headerToken)
      .execute();
  },
  /**
   * login
   */
  async login(username: string, password: string, code: string, codeToken?: string, ip?: string, response?: any, settings?: Partial<Settings>) {
    return new Runtime<LoginResponse>()
      .open('POST', '/user/login', settings)
      .addRequestParam('username', username)
      .addRequestParam('password', password)
      .addRequestParam('code', code)
      .addRequestParam('codeToken', codeToken)
      .addRequestParam('ip', ip)
      .addModelAttribute(response)
      .execute();
  },
  /**
   * loginApp
   */
  async loginApp(request: AppLoginRequest, ip?: string, settings?: Partial<Settings>) {
    return new Runtime<LoginResponse>()
      .open('POST', '/user/login/app', settings)
      .addRequestBody(request)
      .addRequestParam('ip', ip)
      .execute();
  },
  /**
   * loginWithCompanyToken
   */
  async loginWithCompanyToken(token: string, nonce: string, timestamp: Long, mobile: string, ip?: string, response?: any, settings?: Partial<Settings>) {
    return new Runtime<LoginResponse>()
      .open('POST', '/user/login_with_company_login_token', settings)
      .addRequestParam('token', token)
      .addRequestParam('nonce', nonce)
      .addRequestParam('timestamp', timestamp)
      .addRequestParam('mobile', mobile)
      .addRequestParam('ip', ip)
      .addModelAttribute(response)
      .execute();
  },
  /**
   * logout
   */
  async logout(cookie?: string, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/user/logout', settings)
      .addRequestParam('cookie', cookie)
      .execute();
  },
  /**
   * sendCode
   */
  async sendCode(mobile: string, clientIp?: string, settings?: Partial<Settings>) {
    return new Runtime<string>()
      .open('POST', '/user/login/sendCode', settings)
      .addRequestParam('mobile', mobile)
      .addRequestParam('clientIp', clientIp)
      .execute();
  },
};

/**
 * blackListController
 * @iface com.weiyirong99.rms.api.controller.auth.BlackListController
 */
export const blackListController = {
  /**
   * add
   */
  async add(companyId: Long, customerId: Long, remark: string, settings?: Partial<Settings>) {
    return new Runtime<Long>()
      .open('POST', '/company/{companyId}/blacklist/add', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('customerId', customerId)
      .addRequestParam('remark', remark)
      .execute();
  },
  /**
   * addBatch
   */
  async addBatch(companyId: Long, request: BatchDropToBlackListParamsDTO, settings?: Partial<Settings>) {
    return new Runtime<Long[]>()
      .open('POST', '/company/{companyId}/blacklist/add_batch', settings)
      .addPathVariable('companyId', companyId)
      .addRequestBody(request)
      .execute();
  },
  /**
   * empty
   */
  async empty(companyId: Long, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('DELETE', '/company/{companyId}/blacklist', settings)
      .addPathVariable('companyId', companyId)
      .execute();
  },
  /**
   * list
   */
  async list(companyId: Long, current?: number, pageSize?: number, params?: ListBlackListParams, settings?: Partial<Settings>) {
    return new Runtime<PageResponse<BlackListItemDTO>>()
      .open('POST', '/company/{companyId}/blacklist', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('current', current)
      .addRequestParam('pageSize', pageSize)
      .addRequestBody(params)
      .execute();
  },
  /**
   * remove
   */
  async remove(companyId: Long, id: Long, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('DELETE', '/company/{companyId}/blacklist/{id}', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('id', id)
      .execute();
  },
};

/**
 * companyController
 * @iface com.weiyirong99.rms.api.controller.auth.CompanyController
 */
export const companyController = {
  /**
   * createDepartment
   */
  async createDepartment(companyId: Long, addDepartmentRequest: AddDepartmentRequest, settings?: Partial<Settings>) {
    return new Runtime<DepartmentDTO>()
      .open('POST', '/company/{companyId}/department', settings)
      .addPathVariable('companyId', companyId)
      .addRequestBody(addDepartmentRequest)
      .execute();
  },
  /**
   * getCompany
   */
  async getCompany(companyId: Long, settings?: Partial<Settings>) {
    return new Runtime<CompanyDTO>()
      .open('GET', '/company/{companyId}', settings)
      .addPathVariable('companyId', companyId)
      .execute();
  },
  /**
   * getCompanyList
   */
  async getCompanyList(companyId: string, settings?: Partial<Settings>) {
    return new Runtime<CompanyListItem[]>()
      .open('GET', '/company/{companyId}/company_list', settings)
      .addPathVariable('companyId', companyId)
      .execute();
  },
  /**
   * listAllDepartment
   */
  async listAllDepartment(companyId: Long, settings?: Partial<Settings>) {
    return new Runtime<BaseDepartmentDTO[]>()
      .open('GET', '/company/{companyId}/department/all', settings)
      .addPathVariable('companyId', companyId)
      .execute();
  },
  /**
   * listDepartment
   */
  async listDepartment(companyId: Long, settings?: Partial<Settings>) {
    return new Runtime<BaseDepartmentDTO[]>()
      .open('GET', '/company/{companyId}/department', settings)
      .addPathVariable('companyId', companyId)
      .execute();
  },
  /**
   * removeDepartment
   */
  async removeDepartment(companyId: Long, depId: Long, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('DELETE', '/company/{companyId}/department/{depId}', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('depId', depId)
      .execute();
  },
  /**
   * updateCompanyConfig
   */
  async updateCompanyConfig(companyId: Long, config: CompanyConfig, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('PUT', '/company/{companyId}/config', settings)
      .addPathVariable('companyId', companyId)
      .addRequestBody(config)
      .execute();
  },
  /**
   * updateDepartment
   */
  async updateDepartment(companyId: Long, depId: Long, addDepartmentRequest: AddDepartmentRequest, settings?: Partial<Settings>) {
    return new Runtime<DepartmentDTO>()
      .open('PUT', '/company/{companyId}/department/{depId}', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('depId', depId)
      .addRequestBody(addDepartmentRequest)
      .execute();
  },
  /**
   * uploadLogo
   */
  async uploadLogo(companyId: Long, logo: MultipartFile, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/company/{companyId}/logo', settings)
      .addPathVariable('companyId', companyId)
      .addMultipartFile('logo', logo)
      .execute();
  },
};

/**
 * companyMessageController
 * @iface com.weiyirong99.rms.api.controller.auth.CompanyMessageController
 */
export const companyMessageController = {
  /**
   * createMessage
   */
  async createMessage(companyId: Long, title: string, body?: string, image?: MultipartFile, settings?: Partial<Settings>) {
    return new Runtime<Long>()
      .open('POST', '/company/{companyId}/company_message', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('title', title)
      .addRequestParam('body', body)
      .addMultipartFile('image', image)
      .execute();
  },
  /**
   * getMessage
   */
  async getMessage(companyId: Long, messageId: Long, settings?: Partial<Settings>) {
    return new Runtime<CompanyMessageDTO>()
      .open('GET', '/company/{companyId}/company_message/{messageId}', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('messageId', messageId)
      .execute();
  },
  /**
   * listMessage
   */
  async listMessage(companyId: Long, page?: number, size?: number, settings?: Partial<Settings>) {
    return new Runtime<PageResponse<CompanyMessageListItemDTO>>()
      .open('GET', '/company/{companyId}/company_message/list', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('page', page)
      .addRequestParam('size', size)
      .execute();
  },
  /**
   * listTopMessage
   */
  async listTopMessage(companyId: Long, settings?: Partial<Settings>) {
    return new Runtime<CompanyMessageListItemDTO[]>()
      .open('GET', '/company/{companyId}/company_message/list_top', settings)
      .addPathVariable('companyId', companyId)
      .execute();
  },
  /**
   * removeMessage
   */
  async removeMessage(companyId: Long, messageId: Long, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('DELETE', '/company/{companyId}/company_message/{messageId}', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('messageId', messageId)
      .execute();
  },
  /**
   * setTopMessage
   */
  async setTopMessage(companyId: Long, messageId: Long, top: boolean, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('PUT', '/company/{companyId}/company_message/{messageId}/top', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('messageId', messageId)
      .addRequestParam('top', top)
      .execute();
  },
  /**
   * transferMessage
   */
  async transferMessage(companyId: Long, messageId: Long, targetCompanyId: Long[], settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/company/{companyId}/company_message/{messageId}/transfer', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('messageId', messageId)
      .addRequestParam('targetCompanyId', targetCompanyId)
      .execute();
  },
  /**
   * updateMessage
   */
  async updateMessage(companyId: Long, messageId: Long, title: string, body: string, image?: MultipartFile, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('PUT', '/company/{companyId}/company_message/{messageId}', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('messageId', messageId)
      .addRequestParam('title', title)
      .addRequestParam('body', body)
      .addMultipartFile('image', image)
      .execute();
  },
};

/**
 * companyStudyController
 * @iface com.weiyirong99.rms.api.controller.auth.CompanyStudyController
 */
export const companyStudyController = {
  /**
   * copy
   */
  async copy(companyId: Long, mediaId: Long, targetCompanyId: Long, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/company/{companyId}/study/copy', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('mediaId', mediaId)
      .addRequestParam('targetCompanyId', targetCompanyId)
      .execute();
  },
  /**
   * create
   */
  async create(companyId: Long, dto?: AddCompanyStudyMediaDTO, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/company/{companyId}/study', settings)
      .addPathVariable('companyId', companyId)
      .addRequestBody(dto)
      .execute();
  },
  /**
   * list
   */
  async list(companyId: Long, params?: ListCompanyStudyMediaParamsDTO, current?: number, pageSize?: number, settings?: Partial<Settings>) {
    return new Runtime<PageResponse<ListCompanyStudyMediaItemDTO>>()
      .open('POST', '/company/{companyId}/study/list', settings)
      .addPathVariable('companyId', companyId)
      .addRequestBody(params)
      .addRequestParam('current', current)
      .addRequestParam('pageSize', pageSize)
      .execute();
  },
  /**
   * listCategory
   */
  async listCategory(companyId: Long, settings?: Partial<Settings>) {
    return new Runtime<string[]>()
      .open('GET', '/company/{companyId}/study/category', settings)
      .addPathVariable('companyId', companyId)
      .execute();
  },
  /**
   * play
   */
  async play(companyId: Long, mediaId: Long, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('PUT', '/company/{companyId}/study{mediaId}/play', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('mediaId', mediaId)
      .execute();
  },
  /**
   * remove
   */
  async remove(companyId: Long, mediaId: Long, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('DELETE', '/company/{companyId}/study{mediaId}', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('mediaId', mediaId)
      .execute();
  },
  /**
   * transfer
   */
  async transfer(companyId: Long, mediaId: Long, targetCompanyId: Long[], settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/company/{companyId}/study/{mediaId}/transfer', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('mediaId', mediaId)
      .addRequestParam('targetCompanyId', targetCompanyId)
      .execute();
  },
  /**
   * updatePermission
   */
  async updatePermission(companyId: Long, category: string, departmentIds: Long[], settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/company/{companyId}/study/permission', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('category', category)
      .addRequestParam('departmentIds', departmentIds)
      .execute();
  },
};

/**
 * customerController
 * @iface com.weiyirong99.rms.api.controller.auth.CustomerController
 */
export const customerController = {
  /**
   * addAlarm
   */
  async addAlarm(companyId: Long, id: Long, alarmMinutes: number, message: string, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/company/{companyId}/customers/{id}/alarm', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('id', id)
      .addRequestParam('alarmMinutes', alarmMinutes)
      .addRequestParam('message', message)
      .execute();
  },
  /**
   * addEvent
   */
  async addEvent(companyId: Long, id: Long, type: CustomerEventTypeEnum, comment: string, remark?: string, settings?: Partial<Settings>) {
    return new Runtime<CustomerEventDTO>()
      .open('POST', '/company/{companyId}/customers/{id}/events', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('id', id)
      .addRequestParam('type', type)
      .addRequestParam('comment', comment)
      .addRequestParam('remark', remark)
      .execute();
  },
  /**
   * addLeaderEvent
   */
  async addLeaderEvent(companyId: Long, id: Long, comment: string, settings?: Partial<Settings>) {
    return new Runtime<CustomerEventDTO>()
      .open('POST', '/company/{companyId}/customers/{id}/leaderEvent', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('id', id)
      .addRequestParam('comment', comment)
      .execute();
  },
  /**
   * callCustomer
   */
  async callCustomer(companyId: Long, id: Long, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/company/{companyId}/customers/{id}/call', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('id', id)
      .execute();
  },
  /**
   * createCustomer
   */
  async createCustomer(companyId: Long, params: AddCustomerDTO, settings?: Partial<Settings>) {
    return new Runtime<CustomerDTO>()
      .open('POST', '/company/{companyId}/customers', settings)
      .addPathVariable('companyId', companyId)
      .addRequestBody(params)
      .execute();
  },
  /**
   * dropToPublic
   */
  async dropToPublic(companyId: Long, id: Long, comment?: string, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('PUT', '/company/{companyId}/customers/{id}/drop_public', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('id', id)
      .addRequestParam('comment', comment)
      .execute();
  },
  /**
   * dropToPublicBatch
   */
  async dropToPublicBatch(companyId: Long, id: Long[], comment?: string, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('PUT', '/company/{companyId}/customers/drop_public_batch', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('id', id)
      .addRequestParam('comment', comment)
      .execute();
  },
  /**
   * getDetail
   */
  async getDetail(companyId: Long, id: Long, settings?: Partial<Settings>) {
    return new Runtime<CustomerDTO>()
      .open('GET', '/company/{companyId}/customers/{id}', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('id', id)
      .execute();
  },
  /**
   * listChannel
   */
  async listChannel(companyId: Long, settings?: Partial<Settings>) {
    return new Runtime<string[]>()
      .open('GET', '/company/{companyId}/customers/channel', settings)
      .addPathVariable('companyId', companyId)
      .execute();
  },
  /**
   * listCustomer
   */
  async listCustomer(companyId: Long, params?: ListCustomerParamsDTO, listType?: ListCustomerType, group?: CustomerGroupEnum, current?: number, pageSize?: number, settings?: Partial<Settings>) {
    return new Runtime<PageResponse<CustomerListItemDTO>>()
      .open('POST', '/company/{companyId}/customers/list', settings)
      .addPathVariable('companyId', companyId)
      .addRequestBody(params)
      .addRequestParam('listType', listType)
      .addRequestParam('group', group)
      .addRequestParam('current', current)
      .addRequestParam('pageSize', pageSize)
      .execute();
  },
  /**
   * listCustomerV2
   */
  async listCustomerV2(companyId: Long, params?: ListCustomerParamsDTO, listType?: ListCustomerType, group?: CustomerGroupEnum, current?: number, pageSize?: number, settings?: Partial<Settings>) {
    return new Runtime<PageResponse<CustomerListItemV2DTO>>()
      .open('POST', '/company/{companyId}/customers/list_v2', settings)
      .addPathVariable('companyId', companyId)
      .addRequestBody(params)
      .addRequestParam('listType', listType)
      .addRequestParam('group', group)
      .addRequestParam('current', current)
      .addRequestParam('pageSize', pageSize)
      .execute();
  },
  /**
   * listEvent
   */
  async listEvent(companyId: Long, id: Long, settings?: Partial<Settings>) {
    return new Runtime<EventDTO[]>()
      .open('GET', '/company/{companyId}/customers/{id}/events', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('id', id)
      .execute();
  },
  /**
   * pickCustomer
   */
  async pickCustomer(companyId: Long, customerIdList: Long[], settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/company/{companyId}/customers/pick', settings)
      .addPathVariable('companyId', companyId)
      .addRequestBody(customerIdList)
      .execute();
  },
  /**
   * reAllocate
   */
  async reAllocate(companyId: Long, params: ReAllocateCustomerParams[], fromPool: CustomerPoolEnum, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/company/{companyId}/customers/reallocate', settings)
      .addPathVariable('companyId', companyId)
      .addRequestBody(params)
      .addRequestParam('fromPool', fromPool)
      .execute();
  },
  /**
   * transfer
   */
  async transfer(companyId: Long, id: Long, targetUserId: Long, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/company/{companyId}/customers/{id}/transfer', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('id', id)
      .addRequestParam('targetUserId', targetUserId)
      .execute();
  },
  /**
   * updateCustomer
   */
  async updateCustomer(companyId: Long, id: Long, dto: CustomerDTO, settings?: Partial<Settings>) {
    return new Runtime<CustomerDTO>()
      .open('PUT', '/company/{companyId}/customers/{id}', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('id', id)
      .addRequestBody(dto)
      .execute();
  },
  /**
   * updateScore
   */
  async updateScore(companyId: Long, id: Long, score: number, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('PUT', '/company/{companyId}/customers/{id}/score', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('id', id)
      .addRequestParam('score', score)
      .execute();
  },
};

/**
 * dataAnalyzeController
 * @iface com.weiyirong99.rms.api.controller.auth.DataAnalyzeController
 */
export const dataAnalyzeController = {
  /**
   * channelAnalyze
   */
  async channelAnalyze(companyId: Long, request?: ChannelAnalyzeRequest, settings?: Partial<Settings>) {
    return new Runtime<ChannelAnalyzeDTO[]>()
      .open('POST', '/company/{companyId}/channel_analyze', settings)
      .addPathVariable('companyId', companyId)
      .addRequestBody(request)
      .execute();
  },
  /**
   * customerAnalyze
   */
  async customerAnalyze(companyId: Long, start?: string, end?: string, settings?: Partial<Settings>) {
    return new Runtime<CustomerAnalyzeDTO[]>()
      .open('GET', '/company/{companyId}/customer_analyze', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('start', start)
      .addRequestParam('end', end)
      .execute();
  },
  /**
   * handleAnalyse
   */
  async handleAnalyse(companyId: Long, params: HandleAnalyseParams, settings?: Partial<Settings>) {
    return new Runtime<HandleAnalyseDTO[]>()
      .open('POST', '/company/{companyId}/handle_analyze', settings)
      .addPathVariable('companyId', companyId)
      .addRequestBody(params)
      .execute();
  },
  /**
   * homeTop
   */
  async homeTop(companyId: Long, all?: boolean, settings?: Partial<Settings>) {
    return new Runtime<HomeTopDTO[]>()
      .open('GET', '/company/{companyId}/home_top', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('all', all)
      .execute();
  },
  /**
   * listMonthlyData
   */
  async listMonthlyData(companyId: Long, departmentIds: Long[], settings?: Partial<Settings>) {
    return new Runtime<MonthlyDataDTO[]>()
      .open('GET', '/company/{companyId}/monthly_data', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('departmentIds', departmentIds)
      .execute();
  },
  /**
   * loanAnalyze
   */
  async loanAnalyze(companyId: Long, request: LoanAnalyzeRequest, settings?: Partial<Settings>) {
    return new Runtime<LoanAnalyzeDTO[]>()
      .open('POST', '/company/{companyId}/loan_analyze', settings)
      .addPathVariable('companyId', companyId)
      .addRequestBody(request)
      .execute();
  },
  /**
   * mobileTodayAnalyze
   */
  async mobileTodayAnalyze(companyId: Long, settings?: Partial<Settings>) {
    return new Runtime<MobileTodayAnalyzeDTO>()
      .open('GET', '/company/{companyId}/mobile_today_analyze', settings)
      .addPathVariable('companyId', companyId)
      .execute();
  },
  /**
   * mobileTotalAnalyze
   */
  async mobileTotalAnalyze(companyId: Long, start?: string, end?: string, settings?: Partial<Settings>) {
    return new Runtime<MobileTotalAnalyzeDTO[]>()
      .open('GET', '/company/{companyId}/mobile_total_analyze', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('start', start)
      .addRequestParam('end', end)
      .execute();
  },
  /**
   * newDataAnalyze
   */
  async newDataAnalyze(companyId: Long, start: string, end: string, from?: CustomerFromEnum, settings?: Partial<Settings>) {
    return new Runtime<NewDataAnalyzeDTO[]>()
      .open('GET', '/company/{companyId}/new_data_analyze', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('start', start)
      .addRequestParam('end', end)
      .addRequestParam('from', from)
      .execute();
  },
  /**
   * newDataAnalyzeSummary
   */
  async newDataAnalyzeSummary(companyId: Long, depIds?: Long[], settings?: Partial<Settings>) {
    return new Runtime<HomeAnalyseDTO>()
      .open('GET', '/company/{companyId}/new_data_analyze_summary', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('depIds', depIds)
      .execute();
  },
  /**
   * summary
   */
  async summary(companyId: Long, departmentIds: Long[], settings?: Partial<Settings>) {
    return new Runtime<SummaryDTO>()
      .open('GET', '/company/{companyId}/summary', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('departmentIds', departmentIds)
      .execute();
  },
  /**
   * welcomeCoreHeader
   */
  async welcomeCoreHeader(companyId: Long, departmentIds: Long[], settings?: Partial<Settings>) {
    return new Runtime<WelcomeCoreHeaderDataDTO>()
      .open('GET', '/company/{companyId}/welcome_core_header_data', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('departmentIds', departmentIds)
      .execute();
  },
};

/**
 * loanController
 * @iface com.weiyirong99.rms.api.controller.auth.LoanController
 */
export const loanController = {
  /**
   * add
   */
  async add(companyId: Long, addLoanDTO: AddLoanDTO, settings?: Partial<Settings>) {
    return new Runtime<Long>()
      .open('POST', '/company/{companyId}/loan/add', settings)
      .addPathVariable('companyId', companyId)
      .addRequestBody(addLoanDTO)
      .execute();
  },
  /**
   * list
   */
  async list(companyId: Long, current?: number, pageSize?: number, params?: ListCustomerLoanParamsDTO, settings?: Partial<Settings>) {
    return new Runtime<PageResponse<CustomerLoanDTO>>()
      .open('POST', '/company/{companyId}/loan', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('current', current)
      .addRequestParam('pageSize', pageSize)
      .addRequestBody(params)
      .execute();
  },
  /**
   * listBank
   */
  async listBank(companyId: Long, settings?: Partial<Settings>) {
    return new Runtime<string[]>()
      .open('GET', '/company/{companyId}/loan/bank', settings)
      .addPathVariable('companyId', companyId)
      .execute();
  },
  /**
   * remove
   */
  async remove(companyId: Long, id: Long, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('DELETE', '/company/{companyId}/loan/{id}', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('id', id)
      .execute();
  },
};

/**
 * oSSController
 * @iface com.weiyirong99.rms.api.controller.auth.OSSController
 */
export const oSSController = {
  /**
   * getOSSDirectUploadToken
   */
  async getOSSDirectUploadToken(companyId: Long, settings?: Partial<Settings>) {
    return new Runtime<OSSDirectSignature>()
      .open('GET', '/company/{companyId}/oss_direct_upload_token', settings)
      .addPathVariable('companyId', companyId)
      .execute();
  },
  /**
   * getOSSUrl
   */
  async getOSSUrl(companyId: Long, path: string, expire?: Long, settings?: Partial<Settings>) {
    return new Runtime<string>()
      .open('GET', '/company/{companyId}/oss_url', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('path', path)
      .addRequestParam('expire', expire)
      .execute();
  },
};

/**
 * outsideController
 * @iface com.weiyirong99.rms.api.controller.auth.OutsideController
 */
export const outsideController = {
  /**
   * dropToPublic
   */
  async dropToPublic(companyId: Long, size: Long, channel: string, settings?: Partial<Settings>) {
    return new Runtime<string[]>()
      .open('POST', '/company/{companyId}/outside/dropToPublic', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('size', size)
      .addRequestParam('channel', channel)
      .execute();
  },
  /**
   * getTotal
   */
  async getTotal(companyId: Long, settings?: Partial<Settings>) {
    return new Runtime<Long>()
      .open('GET', '/company/{companyId}/outside/total', settings)
      .addPathVariable('companyId', companyId)
      .execute();
  },
  /**
   * importOutside
   */
  async importOutside(companyId: Long, file: MultipartFile, state: CustomerStateEnum, star: number, settings?: Partial<Settings>) {
    return new Runtime<ImportOutsideResponse>()
      .open('POST', '/company/{companyId}/outside/import', settings)
      .addPathVariable('companyId', companyId)
      .addMultipartFile('file', file)
      .addRequestParam('state', state)
      .addRequestParam('star', star)
      .execute();
  },
  /**
   * list
   */
  async list(companyId: Long, current?: number, pageSize?: number, params?: ListOutsideParamsDTO, settings?: Partial<Settings>) {
    return new Runtime<PageResponse<OutsideItemDTO>>()
      .open('POST', '/company/{companyId}/outside', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('current', current)
      .addRequestParam('pageSize', pageSize)
      .addRequestBody(params)
      .execute();
  },
  /**
   * reAllocate
   */
  async reAllocate(companyId: Long, params: OutsideAllocateParamsDTO, settings?: Partial<Settings>) {
    return new Runtime<Long[]>()
      .open('POST', '/company/{companyId}/outside/reAllocate', settings)
      .addPathVariable('companyId', companyId)
      .addRequestBody(params)
      .execute();
  },
  /**
   * remove
   */
  async remove(companyId: Long, id: Long, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('DELETE', '/company/{companyId}/outside/{id}', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('id', id)
      .execute();
  },
};

/**
 * roleController
 * @iface com.weiyirong99.rms.api.controller.auth.RoleController
 */
export const roleController = {
  /**
   * add
   */
  async add(companyId: Long, addRoleRequest: AddRoleRequest, settings?: Partial<Settings>) {
    return new Runtime<RoleDTO>()
      .open('POST', '/company/{companyId}/role', settings)
      .addPathVariable('companyId', companyId)
      .addRequestBody(addRoleRequest)
      .execute();
  },
  /**
   * list
   */
  async list(companyId: Long, settings?: Partial<Settings>) {
    return new Runtime<RoleDTO[]>()
      .open('GET', '/company/{companyId}/role', settings)
      .addPathVariable('companyId', companyId)
      .execute();
  },
  /**
   * listAll
   */
  async listAll(companyId: Long, settings?: Partial<Settings>) {
    return new Runtime<RoleDTO[]>()
      .open('GET', '/company/{companyId}/role/all', settings)
      .addPathVariable('companyId', companyId)
      .execute();
  },
  /**
   * listPermissions
   */
  async listPermissions(companyId: string, roleId: Long, settings?: Partial<Settings>) {
    return new Runtime<RolePermissionDTO[]>()
      .open('GET', '/company/{companyId}/role/{roleId}/permission', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('roleId', roleId)
      .execute();
  },
  /**
   * update
   */
  async update(companyId: Long, roleId: Long, addRoleRequest: AddRoleRequest, settings?: Partial<Settings>) {
    return new Runtime<RoleDTO>()
      .open('PUT', '/company/{companyId}/role/{roleId}', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('roleId', roleId)
      .addRequestBody(addRoleRequest)
      .execute();
  },
  /**
   * updatePermissions
   */
  async updatePermissions(companyId: Long, roleId: Long, codes: string[], settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/company/{companyId}/role/{roleId}/permission', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('roleId', roleId)
      .addRequestBody(codes)
      .execute();
  },
};

/**
 * systemController
 * @iface com.weiyirong99.rms.api.controller.auth.SystemController
 */
export const systemController = {
  /**
   * cleanDeletedCustomerEvent
   */
  async cleanDeletedCustomerEvent(settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/system/clean_deleted_customer_event', settings)
      .execute();
  },
  /**
   * cleanInValidEvent
   */
  async cleanInValidEvent(keyword?: string[], settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/system/clean_invalid_event', settings)
      .addRequestParam('keyword', keyword)
      .execute();
  },
  /**
   * cleanInvalidCustomerEventComment
   */
  async cleanInvalidCustomerEventComment(limit: Long, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/system/clean_invalid_customer_event_comment', settings)
      .addRequestParam('limit', limit)
      .execute();
  },
  /**
   * cleanSomeCustomer
   */
  async cleanSomeCustomer(comments: string[], limit: Long, settings?: Partial<Settings>) {
    return new Runtime<boolean>()
      .open('POST', '/system/clean_some_customer_by_last_event', settings)
      .addRequestParam('comments', comments)
      .addRequestParam('limit', limit)
      .execute();
  },
  /**
   * clearCustomerAndEvent
   */
  async clearCustomerAndEvent(settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/system/clear_customer_and_event', settings)
      .execute();
  },
  /**
   * fixHandledAt
   */
  async fixHandledAt(settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/system/fix_handled_at', settings)
      .execute();
  },
  /**
   * generateCompanyLoginToken
   */
  async generateCompanyLoginToken(id: Long, settings?: Partial<Settings>) {
    return new Runtime<CompanyLoginTokenResponse>()
      .open('POST', '/system/generate_company_login_token', settings)
      .addRequestParam('id', id)
      .execute();
  },
  /**
   * generateMobileHash
   */
  async generateMobileHash(companyId?: Long[], star?: number[], channel?: string[], settings?: Partial<Settings>) {
    return new Runtime<string[]>()
      .open('GET', '/system/generate_mobile_hash', settings)
      .addRequestParam('companyId', companyId)
      .addRequestParam('star', star)
      .addRequestParam('channel', channel)
      .execute();
  },
  /**
   * getInvalidCustomerEventComment
   */
  async getInvalidCustomerEventComment(settings?: Partial<Settings>) {
    return new Runtime<InvalidCustomerEventDTO>()
      .open('GET', '/system/invalid_customer_event_comment', settings)
      .execute();
  },
  /**
   * handleInvalidCustomer
   */
  async handleInvalidCustomer(settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/system/handle_invalid_customer', settings)
      .execute();
  },
  /**
   * listCompany
   */
  async listCompany(settings?: Partial<Settings>) {
    return new Runtime<CompanyDTO[]>()
      .open('GET', '/system/company/list', settings)
      .execute();
  },
  /**
   * splitCompany
   */
  async splitCompany(settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/system/split_company', settings)
      .execute();
  },
  /**
   * testDrop
   */
  async testDrop(settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/system/testdrop', settings)
      .execute();
  },
  /**
   * updateInvalidCustomerEventComment
   */
  async updateInvalidCustomerEventComment(dto: InvalidCustomerEventDTO, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/system/invalid_customer_event_comment', settings)
      .addRequestBody(dto)
      .execute();
  },
};

/**
 * userCallController
 * @iface com.weiyirong99.rms.api.controller.auth.UserCallController
 */
export const userCallController = {
  /**
   * add
   */
  async add(companyId: Long, mobile: string, duration: number | null, createdAt: string, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/company/{companyId}/user-call', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('mobile', mobile)
      .addRequestParam('duration', duration)
      .addRequestParam('createdAt', createdAt)
      .execute();
  },
  /**
   * addCallLog
   */
  async addCallLog(companyId: Long, params: AddCallLogParamsDTO[], settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/company/{companyId}/user-call-log', settings)
      .addPathVariable('companyId', companyId)
      .addRequestBody(params)
      .execute();
  },
};

/**
 * userController
 * @iface com.weiyirong99.rms.api.controller.auth.UserController
 */
export const userController = {
  /**
   * addUser
   */
  async addUser(companyId: Long, addUserRequest: AddUserRequest, settings?: Partial<Settings>) {
    return new Runtime<UserDTO>()
      .open('POST', '/company/{companyId}/user', settings)
      .addPathVariable('companyId', companyId)
      .addRequestBody(addUserRequest)
      .execute();
  },
  /**
   * countAllUserNewCustomerLimit
   */
  async countAllUserNewCustomerLimit(companyId: Long, depId?: Long, settings?: Partial<Settings>) {
    return new Runtime<number>()
      .open('GET', '/company/{companyId}/user/total_limit', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('depId', depId)
      .execute();
  },
  /**
   * countAllUserNewCustomerLimitV2
   */
  async countAllUserNewCustomerLimitV2(companyId: Long, params: ListUserV2Params, settings?: Partial<Settings>) {
    return new Runtime<UserCountAllNewCustomerLimitResponse>()
      .open('POST', '/company/{companyId}/user/total_limit_v2', settings)
      .addPathVariable('companyId', companyId)
      .addRequestBody(params)
      .execute();
  },
  /**
   * get
   */
  async get(companyId: Long, userId: Long, settings?: Partial<Settings>) {
    return new Runtime<GetUserInfoDTO>()
      .open('GET', '/company/{companyId}/user/{userId}', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('userId', userId)
      .execute();
  },
  /**
   * getUserConfig
   */
  async getUserConfig(companyId: Long, userId: Long, settings?: Partial<Settings>) {
    return new Runtime<UserConfigDTO>()
      .open('GET', '/company/{companyId}/user/{userId}/config', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('userId', userId)
      .execute();
  },
  /**
   * listAllBaseUser
   */
  async listAllBaseUser(companyId: Long, settings?: Partial<Settings>) {
    return new Runtime<BaseUserDTO[]>()
      .open('GET', '/company/{companyId}/user/all_base', settings)
      .addPathVariable('companyId', companyId)
      .execute();
  },
  /**
   * listAllUser
   */
  async listAllUser(companyId: Long, settings?: Partial<Settings>) {
    return new Runtime<BaseUserDTO[]>()
      .open('GET', '/company/{companyId}/user/all', settings)
      .addPathVariable('companyId', companyId)
      .execute();
  },
  /**
   * listLatest
   */
  async listLatest(companyId: Long, settings?: Partial<Settings>) {
    return new Runtime<UserMessageListItemDTO[]>()
      .open('GET', '/company/{companyId}/user/message/latest', settings)
      .addPathVariable('companyId', companyId)
      .execute();
  },
  /**
   * listMessage
   */
  async listMessage(companyId: Long, current?: number, pageSize?: number, isRead?: boolean, settings?: Partial<Settings>) {
    return new Runtime<PageResponse<UserMessageListItemDTO>>()
      .open('GET', '/company/{companyId}/user/message', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('current', current)
      .addRequestParam('pageSize', pageSize)
      .addRequestParam('isRead', isRead)
      .execute();
  },
  /**
   * listOnline
   */
  async listOnline(companyId: Long, type: string, settings?: Partial<Settings>) {
    return new Runtime<Long[]>()
      .open('GET', '/company/{companyId}/user/online', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('type', type)
      .execute();
  },
  /**
   * listUser
   */
  async listUser(companyId: Long, current: number | null, pageSize: number | null, params: ListUserV2Params, settings?: Partial<Settings>) {
    return new Runtime<PageResponse<ListUserItemDTO>>()
      .open('POST', '/company/{companyId}/user/list', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('current', current)
      .addRequestParam('pageSize', pageSize)
      .addRequestBody(params)
      .execute();
  },
  /**
   * readMessage
   */
  async readMessage(companyId: Long, messageId: Long, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('PUT', '/company/{companyId}/user/message/{messageId}', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('messageId', messageId)
      .execute();
  },
  /**
   * resetPassword
   */
  async resetPassword(companyId: Long, userId: Long, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/company/{companyId}/user/{userId}/restPassword', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('userId', userId)
      .execute();
  },
  /**
   * switchUser
   */
  async switchUser(companyId: Long, userId: Long, ip?: string, settings?: Partial<Settings>) {
    return new Runtime<SwitchUserResponse>()
      .open('POST', '/company/{companyId}/user/{userId}/switch', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('userId', userId)
      .addRequestParam('ip', ip)
      .execute();
  },
  /**
   * switchVacation
   */
  async switchVacation(companyId: Long, userId: Long, isVacation: boolean, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('PUT', '/company/{companyId}/user/{userId}/switch_vacation', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('userId', userId)
      .addRequestParam('isVacation', isVacation)
      .execute();
  },
  /**
   * transfer
   */
  async transfer(companyId: Long, userId: Long, depId: Long, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/company/{companyId}/user/{userId}/transfer', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('userId', userId)
      .addRequestParam('depId', depId)
      .execute();
  },
  /**
   * updateAvatar
   */
  async updateAvatar(companyId: Long, userId: Long, avatar: MultipartFile, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('PUT', '/company/{companyId}/user/{userId}/avatar', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('userId', userId)
      .addMultipartFile('avatar', avatar)
      .execute();
  },
  /**
   * updateIdCard
   */
  async updateIdCard(companyId: Long, userId: Long, frontend: MultipartFile, backend: MultipartFile, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('PUT', '/company/{companyId}/user/{userId}/id_card', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('userId', userId)
      .addMultipartFile('frontend', frontend)
      .addMultipartFile('backend', backend)
      .execute();
  },
  /**
   * updatePassword
   */
  async updatePassword(companyId: Long, oldPassword: string, newPassword: string, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('PUT', '/company/{companyId}/user/password', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('oldPassword', oldPassword)
      .addRequestParam('newPassword', newPassword)
      .execute();
  },
  /**
   * updateUser
   */
  async updateUser(companyId: Long, userId: Long, updateUserRequest: AddUserRequest, settings?: Partial<Settings>) {
    return new Runtime<UserDTO>()
      .open('PUT', '/company/{companyId}/user/{userId}', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('userId', userId)
      .addRequestBody(updateUserRequest)
      .execute();
  },
  /**
   * updateUserConfig
   */
  async updateUserConfig(companyId: Long, userId: Long, updateUserConfigDTO: UpdateUserConfigDTO, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('PUT', '/company/{companyId}/user/{userId}/config', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('userId', userId)
      .addRequestBody(updateUserConfigDTO)
      .execute();
  },
};

/**
 * userFeedbackController
 * @iface com.weiyirong99.rms.api.controller.auth.UserFeedbackController
 */
export const userFeedbackController = {
  /**
   * create
   */
  async create(companyId: Long, body: string, anon: boolean, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/company/{companyId}/user_feedback', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('body', body)
      .addRequestParam('anon', anon)
      .execute();
  },
  /**
   * get
   */
  async get(companyId: Long, id: Long, settings?: Partial<Settings>) {
    return new Runtime<UserFeedbackListItemDTO>()
      .open('GET', '/company/{companyId}/user_feedback/{id}', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('id', id)
      .execute();
  },
  /**
   * list
   */
  async list(companyId: Long, page?: number, pageSize?: number, settings?: Partial<Settings>) {
    return new Runtime<PageResponse<UserFeedbackListItemDTO>>()
      .open('GET', '/company/{companyId}/user_feedback', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('page', page)
      .addRequestParam('pageSize', pageSize)
      .execute();
  },
};

/**
 * utilsController
 * @iface com.weiyirong99.rms.api.controller.auth.UtilsController
 */
export const utilsController = {
  /**
   * updateLastEventId
   */
  async updateLastEventId(settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/utils/updateLastEventId', settings)
      .execute();
  },
  /**
   * updateTrace
   */
  async updateTrace(settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/utils/updateTrace', settings)
      .execute();
  },
};

/**
 * visitorController
 * @iface com.weiyirong99.rms.api.controller.auth.VisitorController
 */
export const visitorController = {
  /**
   * add
   */
  async add(companyId: Long, name: string, mobile: string, agentName: string | null, agentMobile: string | null, star: number, isSign: boolean, point?: number, amount?: number, contractId?: string, qualification?: string, liability?: string, remark?: string, contractImg?: MultipartFile, signImg?: MultipartFile, settings?: Partial<Settings>) {
    return new Runtime<Long>()
      .open('POST', '/company/{companyId}/visitor/add', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('name', name)
      .addRequestParam('mobile', mobile)
      .addRequestParam('agentName', agentName)
      .addRequestParam('agentMobile', agentMobile)
      .addRequestParam('star', star)
      .addRequestParam('isSign', isSign)
      .addRequestParam('point', point)
      .addRequestParam('amount', amount)
      .addRequestParam('contractId', contractId)
      .addRequestParam('qualification', qualification)
      .addRequestParam('liability', liability)
      .addRequestParam('remark', remark)
      .addMultipartFile('contractImg', contractImg)
      .addMultipartFile('signImg', signImg)
      .execute();
  },
  /**
   * checkMobile
   */
  async checkMobile(companyId: Long, mobile: string, settings?: Partial<Settings>) {
    return new Runtime<boolean>()
      .open('POST', '/company/{companyId}/visitor_mobile_check', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('mobile', mobile)
      .execute();
  },
  /**
   * getData
   */
  async getData(companyId: Long, start: string, end: string, departmentId?: Long, settings?: Partial<Settings>) {
    return new Runtime<VisitorListItemDTO[]>()
      .open('GET', '/company/{companyId}/visitor/data', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('start', start)
      .addRequestParam('end', end)
      .addRequestParam('departmentId', departmentId)
      .execute();
  },
  /**
   * list
   */
  async list(companyId: Long, current?: number, pageSize?: number, params?: ListVisitorParamsDTO, settings?: Partial<Settings>) {
    return new Runtime<PageResponse<VisitorListItemDTO>>()
      .open('POST', '/company/{companyId}/visitor', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('current', current)
      .addRequestParam('pageSize', pageSize)
      .addRequestBody(params)
      .execute();
  },
  /**
   * remove
   */
  async remove(companyId: Long, id: Long, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('DELETE', '/company/{companyId}/visitor/{id}', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('id', id)
      .execute();
  },
  /**
   * update
   */
  async update(companyId: Long, id: Long, dto: UpdateVisitorParamsDTO, settings?: Partial<Settings>) {
    return new Runtime<void>()
      .open('POST', '/company/{companyId}/visitor/update/{id}', settings)
      .addPathVariable('companyId', companyId)
      .addPathVariable('id', id)
      .addRequestBody(dto)
      .execute();
  },
};

/**
 * openAPIController
 * @iface com.weiyirong99.rms.api.controller.openapi.OpenAPIController
 */
export const openAPIController = {
  /**
   * channelAnalyze
   */
  async channelAnalyze(companyId: Long, request?: ChannelAnalyzeRequest, settings?: Partial<Settings>) {
    return new Runtime<ChannelAnalyzeDTO[]>()
      .open('POST', '/openapi/{companyId}/channel_analyze', settings)
      .addPathVariable('companyId', companyId)
      .addRequestBody(request)
      .execute();
  },
  /**
   * createCustomer
   */
  async createCustomer(companyId: Long, request: CreateCustomerRequest, settings?: Partial<Settings>) {
    return new Runtime<OpenAPICreateCustomerResponse>()
      .open('POST', '/openapi/{companyId}/customer', settings)
      .addPathVariable('companyId', companyId)
      .addRequestBody(request)
      .execute();
  },
  /**
   * generateMobileHash
   */
  async generateMobileHash(star?: number[], settings?: Partial<Settings>) {
    return new Runtime<string[]>()
      .open('GET', '/openapi/{companyId}/generate_mobile_hash', settings)
      .addRequestParam('star', star)
      .execute();
  },
  /**
   * generateMobileHashV2
   */
  async generateMobileHashV2(companyId: Long, star?: number[], settings?: Partial<Settings>) {
    return new Runtime<string[][]>()
      .open('GET', '/openapi/{companyId}/generate_mobile_hash_v2', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('star', star)
      .execute();
  },
  /**
   * handleAnalyse
   */
  async handleAnalyse(companyId: Long, params: HandleAnalyseParams, settings?: Partial<Settings>) {
    return new Runtime<HandleAnalyseDTO[]>()
      .open('POST', '/openapi/{companyId}/handle_analyze', settings)
      .addPathVariable('companyId', companyId)
      .addRequestBody(params)
      .execute();
  },
  /**
   * isCustomerExist
   */
  async isCustomerExist(companyId: Long, mobile: string, channel?: string, settings?: Partial<Settings>) {
    return new Runtime<boolean>()
      .open('GET', '/openapi/{companyId}/customer_exist', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('mobile', mobile)
      .addRequestParam('channel', channel)
      .execute();
  },
  /**
   * listAllChannels
   */
  async listAllChannels(companyId: Long, settings?: Partial<Settings>) {
    return new Runtime<string[]>()
      .open('GET', '/openapi/{companyId}/all_channels', settings)
      .addPathVariable('companyId', companyId)
      .execute();
  },
  /**
   * listCustomerByMobile
   */
  async listCustomerByMobile(companyId: Long, mobileList: string[], settings?: Partial<Settings>) {
    return new Runtime<OpenapiCustomerDTO[]>()
      .open('GET', '/openapi/{companyId}/list', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('mobileList', mobileList)
      .execute();
  },
  /**
   * listDepartment
   */
  async listDepartment(companyId: Long, settings?: Partial<Settings>) {
    return new Runtime<BaseDepartmentDTO[]>()
      .open('GET', '/openapi/{companyId}/departments', settings)
      .addPathVariable('companyId', companyId)
      .execute();
  },
  /**
   * listUser
   */
  async listUser(companyId: Long, settings?: Partial<Settings>) {
    return new Runtime<RMSUserDTO[]>()
      .open('GET', '/openapi/{companyId}/users', settings)
      .addPathVariable('companyId', companyId)
      .execute();
  },
  /**
   * newDataAnalyze
   */
  async newDataAnalyze(companyId: Long, start: string, end: string, from?: CustomerFromEnum, settings?: Partial<Settings>) {
    return new Runtime<NewDataAnalyzeDTO[]>()
      .open('GET', '/openapi/{companyId}/new_data_analyze', settings)
      .addPathVariable('companyId', companyId)
      .addRequestParam('start', start)
      .addRequestParam('end', end)
      .addRequestParam('from', from)
      .execute();
  },
};

/**
 * openApiWebMvcResource
 * @iface org.springdoc.webmvc.api.OpenApiWebMvcResource
 */
export const openApiWebMvcResource = {
  /**
   * openapiJson
   */
  async openapiJson(request?: any, apiDocsUrl?: string, locale?: any, settings?: Partial<Settings>) {
    return new Runtime<string>()
      .open('GET', '/v3/api-docs', settings)
      .addModelAttribute(request)
      .addRequestParam('apiDocsUrl', apiDocsUrl)
      .addRequestParam('locale', locale)
      .execute();
  },
  /**
   * openapiYaml
   */
  async openapiYaml(request?: any, apiDocsUrl?: string, locale?: any, settings?: Partial<Settings>) {
    return new Runtime<string>()
      .open('GET', '/v3/api-docs.yaml', settings)
      .addModelAttribute(request)
      .addRequestParam('apiDocsUrl', apiDocsUrl)
      .addRequestParam('locale', locale)
      .execute();
  },
};

/**
 * swaggerConfigResource
 * @iface org.springdoc.webmvc.ui.SwaggerConfigResource
 */
export const swaggerConfigResource = {
  /**
   * openapiJson
   */
  async openapiJson(request?: any, settings?: Partial<Settings>) {
    return new Runtime<Record<string, any>>()
      .open('GET', '/v3/api-docs/swagger-config', settings)
      .addModelAttribute(request)
      .execute();
  },
};

/**
 * swaggerWelcomeWebMvc
 * @iface org.springdoc.webmvc.ui.SwaggerWelcomeWebMvc
 */
export const swaggerWelcomeWebMvc = {
  /**
   * redirectToUi
   */
  async redirectToUi(request?: any, settings?: Partial<Settings>) {
    return new Runtime<any>()
      .open('GET', '/swagger-ui.html', settings)
      .addModelAttribute(request)
      .execute();
  },
};

/**
 * SystemConfigModel
 * @iface com.weiyirong99.rms.api.model.response.SystemConfigModel
 */
export interface SystemConfigModel {
  currentIP?: string;
  customerFroms?: CustomerFromEnum[];
  customerGroups?: CustomerGroupEnum[];
  customerStates?: CustomerStateEnum[];
  permissions?: PermissionCodeModel[];
}

/**
 * PermissionCodeModel
 * @iface com.weiyirong99.rms.api.model.response.PermissionCodeModel
 */
export interface PermissionCodeModel {
  code: string;
  description?: string;
  name: string;
  parentCode?: string;
  type: PermissionTypeEnum;
}

/**
 * CurrentUserDTO
 * @iface com.weiyirong99.rms.api.service.dto.CurrentUserDTO
 */
export interface CurrentUserDTO extends UserDTO {
  userConfig?: UserConfigDTO;
}

/**
 * UserDTO
 * @iface com.weiyirong99.rms.api.service.dto.UserDTO
 */
export interface UserDTO extends BaseDTO {
  companyId?: Long;
  departmentId?: Long;
  isSuperAdmin: boolean;
  mobile: string;
  name: string;
  role?: RoleDTO;
  state: UserStateEnum;
}

/**
 * BaseDTO
 * @iface com.weiyirong99.rms.api.service.dto.BaseDTO
 */
export interface BaseDTO {
  createdAt: string;
  id: Long;
  updatedAt: string;
}

/**
 * RoleDTO
 * @iface com.weiyirong99.rms.api.service.dto.RoleDTO
 */
export interface RoleDTO extends BaseDTO {
  companyId?: Long;
  description?: string;
  name: string;
  permissions?: RolePermissionDTO[];
  views?: RoleViewDTO[];
}

/**
 * RolePermissionDTO
 * @iface com.weiyirong99.rms.api.service.dto.RolePermissionDTO
 */
export interface RolePermissionDTO extends BaseDTO {
  code: string;
  role: RoleDTO;
}

/**
 * RoleViewDTO
 * @iface com.weiyirong99.rms.api.service.dto.RoleViewDTO
 */
export interface RoleViewDTO extends BaseDTO {
  role: RoleDTO;
  view: RoleDTO;
}

/**
 * UserConfigDTO
 * @iface com.weiyirong99.rms.api.service.dto.UserConfigDTO
 */
export interface UserConfigDTO extends BaseDTO {
  appRegisterId?: string;
  avatar?: boolean;
  idCard?: boolean;
  loginWithoutSMS?: boolean;
  newCustomerLimit?: number;
  newCustomerStatus?: boolean;
  selfPause?: boolean;
  userId: Long;
}

/**
 * LoginResponse
 * @iface com.weiyirong99.rms.api.model.response.LoginResponse
 */
export interface LoginResponse {
  token: string;
  user: UserDTO;
}

/**
 * AppLoginRequest
 * @iface com.weiyirong99.rms.api.model.request.AppLoginRequest
 */
export interface AppLoginRequest {
  mobile: string;
  password: string;
  registerId: string;
}

/**
 * BatchDropToBlackListParamsDTO
 * @iface com.weiyirong99.rms.api.service.dto.BatchDropToBlackListParamsDTO
 */
export interface BatchDropToBlackListParamsDTO {
  customerIdList: Long[];
  remark?: string;
}

/**
 * BlackListItemDTO
 * @iface com.weiyirong99.rms.api.service.dto.BlackListItemDTO
 */
export interface BlackListItemDTO extends BaseDTO {
  channel?: string;
  companyId: Long;
  mobile: string;
  name?: string;
  remark?: string;
  user?: BaseUserDTO;
}

/**
 * BaseUserDTO
 * @iface com.weiyirong99.rms.api.service.dto.BaseUserDTO
 */
export interface BaseUserDTO {
  departmentId?: Long;
  id: Long;
  mobile?: string;
  name: string;
  roleId?: Long;
  state: UserStateEnum;
}

/**
 * PageResponse
 * @iface com.weiyirong99.rms.api.model.response.PageResponse
 */
export interface PageResponse<T> {
  list?: T[];
  page: number;
  size: number;
  total?: number;
}

/**
 * ListBlackListParams
 * @iface com.weiyirong99.rms.api.service.dto.ListBlackListParams
 */
export interface ListBlackListParams {
  channel?: string;
  createdAt?: string[];
  keyword?: string;
  userId?: Long;
}

/**
 * DepartmentDTO
 * @iface com.weiyirong99.rms.api.service.dto.DepartmentDTO
 */
export interface DepartmentDTO extends BaseDTO {
  companyId?: Long;
  description?: string;
  name?: string;
  parent?: DepartmentDTO;
}

/**
 * AddDepartmentRequest
 * @iface com.weiyirong99.rms.api.model.request.AddDepartmentRequest
 */
export interface AddDepartmentRequest {
  description?: string;
  name: string;
  parentId?: Long;
}

/**
 * CompanyDTO
 * @iface com.weiyirong99.rms.api.service.dto.CompanyDTO
 */
export interface CompanyDTO extends BaseDTO {
  address?: string;
  config?: CompanyConfig;
  description?: string;
  logoOss?: boolean;
  logoUrl?: string;
  name: string;
  openapiToken?: string;
}

/**
 * CompanyConfig
 * @iface com.weiyirong99.rms.api.dao.entity.companyconfig.CompanyConfig
 */
export interface CompanyConfig {
  appLoginExpiredAtHours?: number;
  defaultJoinPublicPoolConfig?: number;
  dingTalkConfig?: DingTalkConfig;
  disableIndexRank?: boolean;
  disableSMSLogin?: boolean;
  enableAppIPWhite?: boolean;
  enableGroupHomeTop?: boolean;
  enableJoinPublicPool?: boolean;
  enableSignPhoto?: boolean;
  globalCompanyMessageId?: Long;
  hideOutsiderSource?: boolean;
  hideReport?: boolean;
  joinPublicPoolConfig?: Record<CustomerStateEnum, JoinPublicPoolConfig>;
  maxPeekCustomer?: number;
  publicPoolTime?: PublicPoolTime[];
  publicPoolWhiteListUserIdList?: Long[];
  sensitiveWordList?: string[];
  studyTime?: PublicPoolTime[];
  whiteIPList?: string[];
}

/**
 * DingTalkConfig
 * @iface com.weiyirong99.rms.api.dao.entity.companyconfig.DingTalkConfig
 */
export interface DingTalkConfig {
  agentId?: string;
  appKey?: string;
  appSecret?: string;
}

/**
 * JoinPublicPoolConfig
 * @iface com.weiyirong99.rms.api.dao.entity.companyconfig.JoinPublicPoolConfig
 */
export interface JoinPublicPoolConfig {
  day?: number;
}

/**
 * PublicPoolTime
 * @iface com.weiyirong99.rms.api.dao.entity.companyconfig.PublicPoolTime
 */
export interface PublicPoolTime {
  enable: boolean;
  end: string;
  start: string;
}

/**
 * CompanyListItem
 * @iface com.weiyirong99.rms.api.model.config.CompanyListItem
 */
export interface CompanyListItem {
  id: Long;
  name: string;
  url: string;
}

/**
 * BaseDepartmentDTO
 * @iface com.weiyirong99.rms.api.service.dto.BaseDepartmentDTO
 */
export interface BaseDepartmentDTO extends BaseDTO {
  companyId: Long;
  description?: string;
  name: string;
  parentId?: Long;
}

/**
 * CompanyMessageDTO
 * @iface com.weiyirong99.rms.api.service.dto.CompanyMessageDTO
 */
export interface CompanyMessageDTO extends BaseDTO {
  body?: string;
  companyId: Long;
  image: boolean;
  imageKey?: string;
  title: string;
  top: boolean;
  userId: Long;
  userName: string;
}

/**
 * CompanyMessageListItemDTO
 * @iface com.weiyirong99.rms.api.service.dto.CompanyMessageListItemDTO
 */
export interface CompanyMessageListItemDTO extends BaseDTO {
  body?: string;
  companyId: Long;
  image: boolean;
  imageKey?: string;
  title: string;
  top: boolean;
  userId: Long;
  userName: string;
}

/**
 * AddCompanyStudyMediaDTO
 * @iface com.weiyirong99.rms.api.service.dto.AddCompanyStudyMediaDTO
 */
export interface AddCompanyStudyMediaDTO {
  category: string;
  description?: string;
  name: string;
  ossKey: string;
  size: Long;
  type: CompanyStudyMediaTypeEnum;
}

/**
 * ListCompanyStudyMediaItemDTO
 * @iface com.weiyirong99.rms.api.service.dto.ListCompanyStudyMediaItemDTO
 */
export interface ListCompanyStudyMediaItemDTO extends BaseDTO {
  category: string;
  companyId: Long;
  count: Long;
  departmentId?: Long;
  description?: string;
  name: string;
  ossKey: string;
  size: Long;
  type: CompanyStudyMediaTypeEnum;
  userId: Long;
  userName?: string;
}

/**
 * ListCompanyStudyMediaParamsDTO
 * @iface com.weiyirong99.rms.api.service.dto.ListCompanyStudyMediaParamsDTO
 */
export interface ListCompanyStudyMediaParamsDTO {
  category?: string;
  departmentIds?: Long[];
  keyword?: string;
  type?: CompanyStudyMediaTypeEnum;
}

/**
 * CustomerEventDTO
 * @iface com.weiyirong99.rms.api.service.dto.CustomerEventDTO
 */
export interface CustomerEventDTO extends BaseDTO {
  comment?: string;
  companyId?: Long;
  customerDTO?: CustomerDTO;
  remark?: string;
  type?: CustomerEventTypeEnum;
  userId?: Long;
}

/**
 * CustomerDTO
 * @iface com.weiyirong99.rms.api.service.dto.CustomerDTO
 */
export interface CustomerDTO extends CustomerBaseDTO {
  aliasName?: string;
  channel?: string;
  companyId: Long;
  count?: number;
  from?: CustomerFromEnum;
  gender?: GenderEnum;
  group?: CustomerGroupEnum;
  loanCount?: number;
  loanSum?: Long;
  mobile: string;
  name: string;
  pool: CustomerPoolEnum;
  remark?: string;
  rmsId?: Long;
  score?: number;
  star?: number;
  state?: CustomerStateEnum;
  user?: UserDTO;
}

/**
 * CustomerBaseDTO
 * @iface com.weiyirong99.rms.api.service.dto.CustomerBaseDTO
 */
export interface CustomerBaseDTO extends CustomerBizInfoDTO {
  createdAt: string;
  id: Long;
  updatedAt: string;
}

/**
 * CustomerBizInfoDTO
 * @iface com.weiyirong99.rms.api.service.dto.CustomerBizInfoDTO
 */
export interface CustomerBizInfoDTO {
  hasBusinessLicense?: boolean;
  hasCar?: boolean;
  hasCreditCard?: boolean;
  hasHouse?: boolean;
  hasInsurance?: boolean;
  hasProvidentFund?: boolean;
  hasSocialSecurity?: boolean;
  hasWages?: boolean;
  isNative?: boolean;
}

/**
 * AddCustomerDTO
 * @iface com.weiyirong99.rms.api.service.dto.AddCustomerDTO
 */
export interface AddCustomerDTO extends CustomerBizInfoDTO {
  group: CustomerGroupEnum;
  mobile: string;
  name: string;
  remark?: string;
  star?: number;
  state: CustomerStateEnum;
}

/**
 * CustomerListItemDTO
 * @iface com.weiyirong99.rms.api.service.dto.CustomerListItemDTO
 */
export interface CustomerListItemDTO extends CustomerBaseDTO {
  aliasName?: string;
  channel?: string;
  count?: number;
  events?: EventDTO[];
  from?: CustomerFromEnum;
  gender?: GenderEnum;
  group?: CustomerGroupEnum;
  loanCount?: number;
  loanSum?: Long;
  mobile?: string;
  name: string;
  pool: CustomerPoolEnum;
  remark?: string;
  rmsId?: Long;
  score?: number;
  star?: number;
  state: CustomerStateEnum;
  trace?: TraceDTO;
  userId?: Long;
  userName?: string;
}

/**
 * EventDTO
 * @iface com.weiyirong99.rms.api.service.dto.EventDTO
 */
export interface EventDTO extends BaseDTO {
  comment?: string;
  customerId: Long;
  remark?: string;
  type: CustomerEventTypeEnum;
  userId: Long;
  userName: string;
}

/**
 * TraceDTO
 * @iface com.weiyirong99.rms.api.service.dto.TraceDTO
 */
export interface TraceDTO extends BaseDTO {
  assignedAt?: string;
  eventAt?: string;
  handledAt?: string;
  signedAt?: string;
  visitedAt?: string;
}

/**
 * ListCustomerParamsDTO
 * @iface com.weiyirong99.rms.api.service.dto.ListCustomerParamsDTO
 */
export interface ListCustomerParamsDTO extends CustomerBizInfoDTO {
  channel?: string;
  channelList?: string[];
  count?: number;
  createdAt?: string[];
  customerId?: Long;
  departmentIdList?: Long[];
  excludeDepartmentIdList?: boolean;
  excludeUserIdList?: boolean;
  from?: CustomerFromEnum;
  group?: CustomerGroupEnum;
  groupList?: CustomerGroupEnum[];
  handledAt?: string[];
  keyword?: string;
  lastEventAt?: string[];
  pool?: CustomerPoolEnum;
  score?: number;
  signedAt?: string[];
  sorter?: Record<string, SearchSortDirection>;
  star?: number;
  state?: CustomerStateEnum;
  stateList?: CustomerStateEnum[];
  userIdList?: Long[];
  userKeyword?: string;
  visitedAt?: string[];
}

/**
 * CustomerListItemV2DTO
 * @iface com.weiyirong99.rms.api.service.dto.CustomerListItemV2DTO
 */
export interface CustomerListItemV2DTO extends CustomerBaseDTO {
  aliasName?: string;
  channel?: string;
  count?: number;
  from?: CustomerFromEnum;
  gender?: GenderEnum;
  group?: CustomerGroupEnum;
  lastEvent?: EventDTO;
  loanCount?: number;
  loanSum?: Long;
  mobile?: string;
  name: string;
  pool: CustomerPoolEnum;
  remark?: string;
  rmsId?: Long;
  score?: number;
  star?: number;
  state: CustomerStateEnum;
  trace?: TraceDTO;
  userId?: Long;
  userName?: string;
}

/**
 * ReAllocateCustomerParams
 * @iface com.weiyirong99.rms.api.service.dto.ReAllocateCustomerParams
 */
export interface ReAllocateCustomerParams {
  customerIds: Long[];
  userId: Long;
}

/**
 * ChannelAnalyzeDTO
 * @iface com.weiyirong99.rms.api.service.dto.analyze.ChannelAnalyzeDTO
 */
export interface ChannelAnalyzeDTO extends BaseDataAnalyzeDTO {
  channel?: string;
  loan?: LoanChannelAnalyzeDTO;
}

/**
 * BaseDataAnalyzeDTO
 * @iface com.weiyirong99.rms.api.service.dto.analyze.BaseDataAnalyzeDTO
 */
export interface BaseDataAnalyzeDTO {
  cnt: Long;
  star0: Long;
  star1: Long;
  star2: Long;
  star3: Long;
  star4: Long;
  star5: Long;
  stateSignedCount: Long;
  stateVisitedCount: Long;
}

/**
 * LoanChannelAnalyzeDTO
 * @iface com.weiyirong99.rms.api.service.dto.LoanChannelAnalyzeDTO
 */
export interface LoanChannelAnalyzeDTO {
  channel?: string;
  cnt?: Long;
  customerCount?: Long;
  loanAmount?: Long;
  loanBrokerage?: Long;
}

/**
 * ChannelAnalyzeRequest
 * @iface com.weiyirong99.rms.api.model.request.ChannelAnalyzeRequest
 */
export interface ChannelAnalyzeRequest {
  depIds?: Long[];
  end?: string;
  fromEnums?: CustomerFromEnum;
  start?: string;
}

/**
 * CustomerAnalyzeDTO
 * @iface com.weiyirong99.rms.api.service.dto.CustomerAnalyzeDTO
 */
export interface CustomerAnalyzeDTO {
  cnt: Long;
  groupA: Long;
  groupB: Long;
  groupC: Long;
  groupD: Long;
  groupE: Long;
  groupExpand: Long;
  groupLocked: Long;
  groupMortgage: Long;
  groupNewAllot: Long;
  groupNewCustomer: Long;
  star0: Long;
  star1: Long;
  star2: Long;
  star3: Long;
  star4: Long;
  star5: Long;
  stateApproval: Long;
  stateBlackList: Long;
  stateExamining: Long;
  stateInvalid: Long;
  stateLoan: Long;
  stateSigned: Long;
  stateUnknown: Long;
  stateUnqualified: Long;
  stateVisited: Long;
  stateWaitFollowUp: Long;
  stateWaitSign: Long;
  user?: BaseUserDTO;
}

/**
 * HandleAnalyseDTO
 * @iface com.weiyirong99.rms.api.service.dto.HandleAnalyseDTO
 */
export interface HandleAnalyseDTO {
  brokerage: Long;
  callInCount: number;
  callInDuration: number;
  callOutCount: number;
  callOutDuration: number;
  comment: number;
  departmentId?: Long;
  distinctComment: number;
  loanAmount: Long;
  loanCount: number;
  sign: number;
  userId: Long;
  userName: string;
  userState: UserStateEnum;
  visitor: number;
  visitorCurMonth: number;
  withAgent: number;
  withAgentCurMonth: number;
  withAgentSign: number;
}

/**
 * HandleAnalyseParams
 * @iface com.weiyirong99.rms.api.service.dto.HandleAnalyseParams
 */
export interface HandleAnalyseParams {
  currentMonthEnd: string;
  currentMonthStart: string;
  departmentId?: Long;
  end: string;
  start: string;
}

/**
 * HomeTopDTO
 * @iface com.weiyirong99.rms.api.service.dto.HomeTopDTO
 */
export interface HomeTopDTO {
  brokerage: Long;
  departmentId?: Long;
  loanAmount: Long;
  loanCount: number;
  userId: Long;
  userName: string;
}

/**
 * MonthlyDataDTO
 * @iface com.weiyirong99.rms.api.service.dto.MonthlyDataDTO
 */
export interface MonthlyDataDTO {
  brokerage?: Long;
  loanAmount?: Long;
  loanCount?: Long;
  month?: number;
  signCount?: Long;
  visitorCount?: Long;
  year?: number;
}

/**
 * LoanAnalyzeDTO
 * @iface com.weiyirong99.rms.api.service.dto.LoanAnalyzeDTO
 */
export interface LoanAnalyzeDTO {
  bank: string;
  brokerage: Long;
  channel: string;
  loanAmount: Long;
  user: BaseUserDTO;
}

/**
 * LoanAnalyzeRequest
 * @iface com.weiyirong99.rms.api.model.request.LoanAnalyzeRequest
 */
export interface LoanAnalyzeRequest {
  end: string;
  start: string;
}

/**
 * MobileTodayAnalyzeDTO
 * @iface com.weiyirong99.rms.api.service.dto.analyze.MobileTodayAnalyzeDTO
 */
export interface MobileTodayAnalyzeDTO {
  callCount?: Long;
  callDuration?: Long;
  callInCount?: Long;
  callInDuration?: Long;
  callOutCount?: Long;
  callOutDuration?: Long;
  eventCount?: Long;
  eventCustomerCount?: Long;
}

/**
 * MobileTotalAnalyzeDTO
 * @iface com.weiyirong99.rms.api.service.dto.analyze.MobileTotalAnalyzeDTO
 */
export interface MobileTotalAnalyzeDTO {
  callCount?: Long;
  callInCount?: Long;
  callOutCount?: Long;
  date?: string;
}

/**
 * NewDataAnalyzeDTO
 * @iface com.weiyirong99.rms.api.service.dto.analyze.NewDataAnalyzeDTO
 */
export interface NewDataAnalyzeDTO extends BaseDataAnalyzeDTO {
  departmentId?: Long;
  userId: Long;
  userName: string;
  userState: UserStateEnum;
  withAgentCount: Long;
  withAgentSignedCount: Long;
  withBrokerage: Long;
  withLoanAmount: Long;
  withLoanCount: Long;
}

/**
 * HomeAnalyseDTO
 * @iface com.weiyirong99.rms.api.service.dto.HomeAnalyseDTO
 */
export interface HomeAnalyseDTO {
  month?: HomeAnalyseItemDTO;
  today?: HomeAnalyseItemDTO;
  week?: HomeAnalyseItemDTO;
  yesterday?: HomeAnalyseItemDTO;
}

/**
 * HomeAnalyseItemDTO
 * @iface com.weiyirong99.rms.api.service.dto.HomeAnalyseItemDTO
 */
export interface HomeAnalyseItemDTO {
  comment?: number;
  day?: number;
  distinctComment?: number;
  loanAmount?: Long;
  loanCount?: number;
  month?: number;
  rmsCustomer?: number;
  userId?: Long;
  visitor?: number;
}

/**
 * SummaryDTO
 * @iface com.weiyirong99.rms.api.service.dto.analyze.summary.SummaryDTO
 */
export interface SummaryDTO {
  event?: EventSummaryDTO;
  loan?: LoanSummaryDTO;
  visitor?: BaseSummaryDatumDTO;
}

/**
 * EventSummaryDTO
 * @iface com.weiyirong99.rms.api.service.dto.analyze.summary.EventSummaryDTO
 */
export interface EventSummaryDTO extends BaseSummaryDatumDTO {
  monthHandleCount: number;
  monthTotal: number;
  todayHandleCount: number;
  todayTotal: number;
  weekHandleCount: number;
  weekTotal: number;
  yesterdayHandleCount: number;
  yesterdayTotal: number;
}

/**
 * BaseSummaryDatumDTO
 * @iface com.weiyirong99.rms.api.service.dto.analyze.summary.BaseSummaryDatumDTO
 */
export interface BaseSummaryDatumDTO {
  month: number;
  today: number;
  week: number;
  yesterday: number;
}

/**
 * LoanSummaryDTO
 * @iface com.weiyirong99.rms.api.service.dto.analyze.summary.LoanSummaryDTO
 */
export interface LoanSummaryDTO extends BaseSummaryDatumDTO {
  monthAmount: Long;
  todayAmount: Long;
  weekAmount: Long;
  yesterdayAmount: Long;
}

/**
 * WelcomeCoreHeaderDataDTO
 * @iface com.weiyirong99.rms.api.service.dto.WelcomeCoreHeaderDataDTO
 */
export interface WelcomeCoreHeaderDataDTO {
  currentMonth: WelcomeCoreDataDTO;
  prevMonth?: WelcomeCoreDataDTO;
}

/**
 * WelcomeCoreDataDTO
 * @iface com.weiyirong99.rms.api.service.dto.WelcomeCoreDataDTO
 */
export interface WelcomeCoreDataDTO {
  brokerage: Long;
  customerCount: Long;
  loanAmount: Long;
  loanCount: Long;
  signCount: Long;
  star0: number;
  star1: number;
  star2: number;
  star3: number;
  star4: number;
  star5: number;
  visitorCount: Long;
}

/**
 * AddLoanDTO
 * @iface com.weiyirong99.rms.api.service.dto.AddLoanDTO
 */
export interface AddLoanDTO {
  bank: string;
  brokerage: Long;
  customerId: Long;
  loanAmount: Long;
  loanedAt: string;
  remark?: string;
  userId: Long;
}

/**
 * CustomerLoanDTO
 * @iface com.weiyirong99.rms.api.service.dto.CustomerLoanDTO
 */
export interface CustomerLoanDTO extends BaseDTO {
  bank?: string;
  brokerage?: Long;
  channel?: string;
  companyId: Long;
  creatorDepartmentId?: Long;
  creatorId: Long;
  creatorName?: string;
  customerCreatedAt?: string;
  customerId: Long;
  customerMobile?: string;
  customerName?: string;
  loanAmount?: Long;
  loanedAt?: string;
  remark?: string;
  userDepartmentId?: Long;
  userId: Long;
  userName?: string;
}

/**
 * ListCustomerLoanParamsDTO
 * @iface com.weiyirong99.rms.api.service.dto.ListCustomerLoanParamsDTO
 */
export interface ListCustomerLoanParamsDTO {
  bank?: string;
  channel?: string;
  createdAtBegin?: string;
  createdAtEnd?: string;
  customerKeyword?: string;
  departmentId?: Long;
  loanId?: Long;
  loanedAtBegin?: string;
  loanedAtEnd?: string;
  userId?: Long;
  userIdList?: Long[];
  userKeyword?: string;
}

/**
 * OSSDirectSignature
 * @iface com.weiyirong99.rms.api.service.dto.OSSDirectSignature
 */
export interface OSSDirectSignature {
  accessKey: string;
  dir: string;
  expire: string;
  host: string;
  policy: string;
  signature: string;
}

/**
 * ImportOutsideResponse
 * @iface com.weiyirong99.rms.api.model.response.ImportOutsideResponse
 */
export interface ImportOutsideResponse {
  success?: Long;
  total?: Long;
}

/**
 * OutsideItemDTO
 * @iface com.weiyirong99.rms.api.service.dto.OutsideItemDTO
 */
export interface OutsideItemDTO extends BaseDTO {
  mobile: string;
  name: string;
  remark?: string;
  star: number;
  state: CustomerStateEnum;
}

/**
 * ListOutsideParamsDTO
 * @iface com.weiyirong99.rms.api.service.dto.ListOutsideParamsDTO
 */
export interface ListOutsideParamsDTO {
  createdAt?: string[];
  mobile?: string;
  star?: number;
  state?: CustomerStateEnum;
}

/**
 * OutsideAllocateParamsDTO
 * @iface com.weiyirong99.rms.api.service.dto.OutsideAllocateParamsDTO
 */
export interface OutsideAllocateParamsDTO {
  allocation?: OutsideAllocateItemDTO[];
  channel?: string;
  group?: CustomerGroupEnum;
  remark?: string;
}

/**
 * OutsideAllocateItemDTO
 * @iface com.weiyirong99.rms.api.service.dto.OutsideAllocateItemDTO
 */
export interface OutsideAllocateItemDTO {
  outsideIds: Long[];
  userId: Long;
}

/**
 * AddRoleRequest
 * @iface com.weiyirong99.rms.api.model.request.AddRoleRequest
 */
export interface AddRoleRequest {
  description?: string;
  name: string;
  viewRoleId?: Long[];
}

/**
 * CompanyLoginTokenResponse
 * @iface com.weiyirong99.rms.api.model.response.CompanyLoginTokenResponse
 */
export interface CompanyLoginTokenResponse {
  mobile: string;
  nonce: string;
  timestamp: string;
  token: string;
}

/**
 * InvalidCustomerEventDTO
 * @iface com.weiyirong99.rms.api.model.config.InvalidCustomerEventDTO
 */
export interface InvalidCustomerEventDTO {
  comments?: string[];
  days?: number;
  enabled?: boolean;
}

/**
 * AddCallLogParamsDTO
 * @iface com.weiyirong99.rms.api.service.dto.AddCallLogParamsDTO
 */
export interface AddCallLogParamsDTO {
  calledAt?: Long;
  duration?: number;
  mobile?: string;
  type?: CallLogTypeEnum;
}

/**
 * AddUserRequest
 * @iface com.weiyirong99.rms.api.model.request.AddUserRequest
 */
export interface AddUserRequest {
  departmentId: Long;
  ignoreCompany?: boolean;
  mobile: string;
  name: string;
  roleId: Long;
  state?: UserStateEnum;
}

/**
 * UserCountAllNewCustomerLimitResponse
 * @iface com.weiyirong99.rms.api.model.response.UserCountAllNewCustomerLimitResponse
 */
export interface UserCountAllNewCustomerLimitResponse {
  count: number;
  countAvailable: number;
}

/**
 * ListUserV2Params
 * @iface com.weiyirong99.rms.api.service.dto.ListUserV2Params
 */
export interface ListUserV2Params {
  companyId: Long;
  departmentIds?: Long[];
  ignoreCompany?: boolean;
  loginWithoutSMS?: boolean;
  name?: string;
  roleId?: Long;
  userStates?: UserStateEnum;
  vacation?: boolean;
}

/**
 * GetUserInfoDTO
 * @iface com.weiyirong99.rms.api.service.dto.GetUserInfoDTO
 */
export interface GetUserInfoDTO extends BaseDTO {
  companyId?: Long;
  departmentId?: Long;
  isSuperAdmin?: boolean;
  mobile: string;
  name: string;
  roleId?: Long;
  state: UserStateEnum;
}

/**
 * UserMessageListItemDTO
 * @iface com.weiyirong99.rms.api.service.dto.UserMessageListItemDTO
 */
export interface UserMessageListItemDTO extends BaseDTO {
  body: string;
  extra?: string;
  isRead: boolean;
  title?: string;
  userId: Long;
}

/**
 * ListUserItemDTO
 * @iface com.weiyirong99.rms.api.service.dto.ListUserItemDTO
 */
export interface ListUserItemDTO extends BaseDTO {
  appOnline: boolean;
  appRegisterId?: string;
  companyId?: Long;
  departmentId?: Long;
  isSuperAdmin: boolean;
  lastIP?: string;
  lastIPAddress?: string;
  lastLoginAt?: string;
  loginWithoutSMS: boolean;
  mobile: string;
  name: string;
  newCustomerLimit?: number;
  newCustomerStatus?: boolean;
  pcOnline: boolean;
  roleId?: Long;
  selfPause?: boolean;
  state: UserStateEnum;
  vacation: boolean;
}

/**
 * SwitchUserResponse
 * @iface com.weiyirong99.rms.api.model.response.SwitchUserResponse
 */
export interface SwitchUserResponse {
  token: string;
}

/**
 * UpdateUserConfigDTO
 * @iface com.weiyirong99.rms.api.service.UpdateUserConfigDTO
 */
export interface UpdateUserConfigDTO {
  appRegisterId?: string;
  loginWithoutSMS?: boolean;
  newCustomerLimit?: number;
  newCustomerStatus?: boolean;
  selfPause?: boolean;
}

/**
 * UserFeedbackListItemDTO
 * @iface com.weiyirong99.rms.api.service.dto.UserFeedbackListItemDTO
 */
export interface UserFeedbackListItemDTO extends BaseDTO {
  anon?: boolean;
  body: string;
  depId?: Long;
  roleId?: Long;
  userId: Long;
  userName: string;
}

/**
 * VisitorListItemDTO
 * @iface com.weiyirong99.rms.api.service.dto.VisitorListItemDTO
 */
export interface VisitorListItemDTO extends BaseDTO {
  agentMobile?: string;
  agentName?: string;
  amount?: number;
  channel?: string;
  contractId?: string;
  customerCreateTime?: string;
  isSign?: boolean;
  liability?: string;
  oid?: Long;
  point?: number;
  qualification?: string;
  remark?: string;
  user: BaseUserDTO;
  visitorMobile: string;
  visitorName: string;
  withContractImg?: boolean;
  withSignImg?: boolean;
}

/**
 * ListVisitorParamsDTO
 * @iface com.weiyirong99.rms.api.service.dto.ListVisitorParamsDTO
 */
export interface ListVisitorParamsDTO {
  agentKeyword?: string;
  channel?: string;
  createdAt?: string[];
  customerCreateTime?: string[];
  departmentId?: Long;
  departmentIds?: Long[];
  isSign?: boolean;
  userId?: Long;
  userIdList?: Long[];
  userKeyword?: string;
  visitorKeyword?: string;
  withAgent?: boolean;
}

/**
 * UpdateVisitorParamsDTO
 * @iface com.weiyirong99.rms.api.service.dto.UpdateVisitorParamsDTO
 */
export interface UpdateVisitorParamsDTO {
  agentMobile?: string;
  agentName?: string;
  amount?: number;
  contractId?: string;
  isSign?: boolean;
  liability?: string;
  point?: number;
  qualification?: string;
  remark?: string;
  visitorMobile: string;
  visitorName: string;
}

/**
 * OpenAPICreateCustomerResponse
 * @iface com.weiyirong99.rms.api.model.response.OpenAPICreateCustomerResponse
 */
export interface OpenAPICreateCustomerResponse {
  inBlackList?: boolean;
  reApply?: boolean;
  userId?: Long;
}

/**
 * CreateCustomerRequest
 * @iface com.weiyirong99.rms.api.model.request.openapi.CreateCustomerRequest
 */
export interface CreateCustomerRequest {
  bizInfo?: CustomerBizInfoDTO;
  channel: string;
  loanAmount?: Long;
  mobile: string;
  name: string;
  remark?: string;
  rmsId: Long;
  userId: Long;
}

/**
 * OpenapiCustomerDTO
 * @iface com.weiyirong99.rms.api.service.dto.OpenapiCustomerDTO
 */
export interface OpenapiCustomerDTO {
  id?: Long;
  lastEvent?: EventDTO;
  mobile?: string;
  rmsId?: Long;
  star?: number;
  state?: CustomerStateEnum;
}

/**
 * RMSUserDTO
 * @iface com.weiyirong99.rms.api.service.dto.RMSUserDTO
 */
export interface RMSUserDTO extends BaseUserDTO {
  newCustomerLimit?: number;
  newCustomerStatus?: boolean;
  selfPause?: boolean;
}

export enum CustomerFromEnum {
  RMS = 'RMS',
  OUTSIDER = 'OUTSIDER',
  MANUAL = 'MANUAL',
  VISIT = 'VISIT',
  CRM = 'CRM',
}

export enum CustomerGroupEnum {
  NEW_CUSTOMER = 'NEW_CUSTOMER',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  NEW_ALLOT = 'NEW_ALLOT',
  EXPAND = 'EXPAND',
  MORTGAGE = 'MORTGAGE',
  LOCKED = 'LOCKED',
}

export enum CustomerStateEnum {
  UNKNOWN = 'UNKNOWN',
  WAIT_FOLLOW_UP = 'WAIT_FOLLOW_UP',
  UNQUALIFIED = 'UNQUALIFIED',
  WAIT_SIGN = 'WAIT_SIGN',
  SIGNED = 'SIGNED',
  VISITED = 'VISITED',
  APPROVAL = 'APPROVAL',
  LOAN = 'LOAN',
  EXAMINING = 'EXAMINING',
  INVALID = 'INVALID',
  BLACK_LIST = 'BLACK_LIST',
}

export enum PermissionTypeEnum {
  MENU = 'MENU',
  API = 'API',
  STATIC = 'STATIC',
  BUTTON = 'BUTTON',
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

export enum UserStateEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
}

export enum CompanyStudyMediaTypeEnum {
  AUDIO = 'AUDIO',
  VIDEO = 'VIDEO',
}

export enum GenderEnum {
  WOMEN = 'WOMEN',
  MAN = 'MAN',
}

export enum CustomerPoolEnum {
  BIZ = 'BIZ',
  PUBLIC = 'PUBLIC',
  WAIT_HANDLE = 'WAIT_HANDLE',
}

export enum CustomerEventTypeEnum {
  COMMENT = 'COMMENT',
  DROP_PUBLIC_POOL = 'DROP_PUBLIC_POOL',
  BLOCK_CALL = 'BLOCK_CALL',
  CALL = 'CALL',
  REASSIGN = 'REASSIGN',
  VISIT = 'VISIT',
  LOAN = 'LOAN',
  REDUPLICATE = 'REDUPLICATE',
  REMARK = 'REMARK',
}

export enum SearchSortDirection {
  ASC = 'ascend', // value=ascend
  DESC = 'descend', // value=descend
}

export enum ListCustomerType {
  MY = 'MY',
  POOL = 'POOL',
}

export enum CallLogTypeEnum {
  OUT = 'OUT',
  IN = 'IN',
}

export type Long = string | number;

export type MultipartFile = Blob | File | string;
