import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  InputRef,
  List,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tag,
  TimePicker,
  Upload,
  message,
} from 'antd';
import { useCompany } from '../../hooks/company';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRequest } from 'ahooks';
import {
  CompanyConfig,
  CustomerStateEnum,
  InvalidCustomerEventDTO,
  JoinPublicPoolConfig,
  PublicPoolTime,
  UserStateEnum,
  companyController,
  systemController,
  userController,
} from '../../api';
import dayjs from 'dayjs';
import { customerStateMapping } from '../../constants';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import OSSImage from '../../components/OSSImage';

interface ConfigProps<T extends CompanyConfig[keyof CompanyConfig]> {
  config: T;
  loading?: boolean;
  onUpdate: (value: T) => void;
}

const LogoConfig = () => {
  const company = useCompany();

  const [n, setN] = useState<number>(0);

  const { run: updateLogo, loading } = useRequest(
    companyController.uploadLogo,
    {
      manual: true,
      onSuccess: () => {
        message.success('更新成功');
        company.refresh();
        setN((v) => v + 1);
      },
      onError: (e) => {
        message.error(e.message || '更新失败');
      },
    },
  );
  const logoUrl = useMemo(() => {
    if (company.logoOss) {
      return `logo/${company.id}`;
    }
    return undefined;
  }, [company]);

  return (
    <Space>
      {logoUrl && <OSSImage path={logoUrl} width={128} reload={n} />}
      <Upload
        beforeUpload={(file) => false}
        accept="image/*"
        multiple={false}
        maxCount={1}
        name="avatar"
        listType="picture-card"
        disabled={loading}
        showUploadList={true}
        // beforeUpload={beforeUpload}
        onChange={(info) => {
          return updateLogo(company.id, info.file as any);
        }}
      >
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>选择图片</div>
        </div>
      </Upload>
    </Space>
  );
};

const IPWhiteList = ({
  config: list,
  onUpdate,
  loading,
  appLoginExpiredAtHours,
  enableAppIPWhite,
}: Omit<ConfigProps<CompanyConfig['whiteIPList']>, 'onUpdate'> &
  Pick<CompanyConfig, 'appLoginExpiredAtHours' | 'enableAppIPWhite'> & {
    onUpdate: (
      v: Pick<
        CompanyConfig,
        'appLoginExpiredAtHours' | 'enableAppIPWhite' | 'whiteIPList'
      >,
    ) => void;
  }) => {
  const { systemConfig } = useCompany();
  const { currentIP } = systemConfig || {};
  const [newIP, setNewIP] = useState<string>();
  const isCurrent = useCallback(
    (ip?: string) => {
      return ip === currentIP;
    },
    [currentIP],
  );
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form, appLoginExpiredAtHours, enableAppIPWhite]);

  return (
    <Row>
      <Col span={24}>
        <List bordered loading={loading} size="small">
          {list?.map((ip) => (
            <List.Item
              actions={[
                <Button
                  loading={loading}
                  danger
                  type="primary"
                  size="small"
                  onClick={() => {
                    onUpdate({
                      whiteIPList: [...new Set(list.filter((v) => v !== ip))],
                    });
                  }}
                >
                  删除
                </Button>,
              ]}
              key={ip}
            >
              <Space>
                <span>{ip}</span>
                {isCurrent(ip) && <Tag>当前</Tag>}
              </Space>
            </List.Item>
          ))}
          <List.Item
            actions={[
              <Button
                type="primary"
                size="small"
                disabled={!newIP?.length}
                onClick={() => {
                  if (newIP) {
                    const result = [...(list || [])];
                    result.push(newIP);
                    onUpdate({ whiteIPList: [...new Set(result)] });
                    setNewIP(undefined);
                  }
                }}
                loading={loading}
              >
                保存
              </Button>,
            ]}
          >
            <Input
              placeholder={`请输入 IP 地址，当前 IP 为${currentIP}`}
              value={newIP}
              onChange={(v) => setNewIP(v.target.value)}
            />
          </List.Item>
        </List>
      </Col>
      <Col span={24}>
        <Form
          initialValues={{
            appLoginExpiredAtHours,
            enableAppIPWhite,
          }}
          onFinish={(values) => {
            return onUpdate(values);
          }}
          form={form}
          style={{ marginTop: '20px' }}
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
        >
          <Form.Item
            label="APP IP 白名单"
            name="enableAppIPWhite"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item label="APP 登录过期时间" name="appLoginExpiredAtHours">
            <InputNumber
              min={1}
              precision={0}
              addonAfter="小时"
              placeholder="默认 24"
              style={{ width: 200 }}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

const UserSelect = ({
  value,
  onChange,
  style,
}: {
  value?: I.ID[];
  onChange: (v: I.ID[]) => void;
  style: React.CSSProperties;
}) => {
  const { id: companyId } = useCompany();
  const { data: users, loading } = useRequest(
    () => {
      return userController
        .listAllBaseUser(companyId)
        .then((v) => v.filter((item) => item.state === UserStateEnum.ACTIVE));
    },
    {
      refreshDeps: [companyId],
    },
  );
  return (
    <Select
      loading={loading}
      style={style}
      mode="multiple"
      value={value}
      onChange={(v) => {
        onChange(v || []);
      }}
      options={users?.map((user) => ({
        key: user.id,
        label: user.name,
        value: user.id,
      }))}
    />
  );
};

const StudyConfig = ({
  config,
  loading,
  onUpdate,
}: Omit<ConfigProps<CompanyConfig['studyTime']>, 'onUpdate'> & {
  config: CompanyConfig['studyTime'];
  onUpdate: (v: Pick<CompanyConfig, 'studyTime'>) => void;
}) => {
  const [value, setValue] = useState<PublicPoolTime[]>();
  const start = '00:00';
  const end = '23:59';
  const enable = false;
  useEffect(() => {
    if (config?.length) {
      setValue(config);
    } else {
      setValue([1, 2, 3, 4, 5, 6, 7].map(() => ({ enable, start, end })));
    }
  }, [config]);

  const getWeekName = (index: number) => {
    const week = ['一', '二', '三', '四', '五', '六', '日'];
    return `周${week[index]}`;
  };

  const updateValue = (idx: number, v: Partial<PublicPoolTime>) => {
    return setValue((prev) => {
      const result = [...(prev || [])];
      result[idx] = {
        ...result[idx],
        ...v,
      };
      return result;
    });
  };

  return (
    <Space direction="vertical">
      {value?.map((v, i) => {
        return (
          <Space key={i}>
            <Checkbox
              checked={v?.enable}
              onChange={(vv) =>
                updateValue(i, {
                  enable: vv.target.checked,
                })
              }
            />
            <span>{getWeekName(i)}</span>
            <TimePicker.RangePicker
              disabled={!v.enable}
              value={[
                dayjs(`2021-01-01 ${v?.start}`),
                dayjs(`2021-01-01 ${v?.end}`),
              ]}
              onChange={(vv) => {
                if (vv?.length === 2) {
                  updateValue(i, {
                    start: vv[0]?.format('HH:mm'),
                    end: vv[1]?.format('HH:mm'),
                  });
                }
              }}
              format={'HH:mm'}
            />
          </Space>
        );
      })}
      <Button
        type="primary"
        loading={loading}
        onClick={() => {
          onUpdate({
            studyTime: value,
          });
        }}
      >
        保存
      </Button>
    </Space>
  );
};

const PublicPoolConfig = ({
  config,
  maxPeekCustomer,
  loading,
  whiteUserList,
  onUpdate,
}: Omit<ConfigProps<CompanyConfig['publicPoolTime']>, 'onUpdate'> & {
  config: CompanyConfig['publicPoolTime'];
  maxPeekCustomer?: number;
  whiteUserList?: I.ID[];
  onUpdate: (
    v: Pick<
      CompanyConfig,
      'publicPoolTime' | 'maxPeekCustomer' | 'publicPoolWhiteListUserIdList'
    >,
  ) => void;
}) => {
  const [value, setValue] = useState<PublicPoolTime[]>();
  const [max, setMax] = useState<number | undefined>(maxPeekCustomer);
  const [publicPoolWhiteListUserIdList, setPublicPoolWhiteListUserIdList] =
    useState<I.ID[] | undefined>(whiteUserList);

  const start = '00:00';
  const end = '23:59';
  const enable = false;

  useEffect(() => {
    if (config?.length) {
      setValue(config);
    } else {
      setValue([1, 2, 3, 4, 5, 6, 7].map(() => ({ enable, start, end })));
    }
  }, [config]);

  const getWeekName = (index: number) => {
    const week = ['一', '二', '三', '四', '五', '六', '日'];
    return `周${week[index]}`;
  };

  const updateValue = (idx: number, v: Partial<PublicPoolTime>) => {
    return setValue((prev) => {
      const result = [...(prev || [])];
      result[idx] = {
        ...result[idx],
        ...v,
      };
      return result;
    });
  };

  return (
    <Space direction="vertical">
      <Space>
        <span>上限白名单</span>
        <UserSelect
          style={{ width: '200px' }}
          value={publicPoolWhiteListUserIdList || []}
          onChange={(v) => {
            setPublicPoolWhiteListUserIdList(v);
          }}
        />
      </Space>
      <Space>
        <span>最大领取客户数</span>
        <InputNumber
          style={{ width: '178px' }}
          value={max}
          onChange={(v) => setMax(v || 0)}
          precision={0}
        />
      </Space>
      {value?.map((v, i) => {
        return (
          <Space key={i}>
            <Checkbox
              checked={v?.enable}
              onChange={(vv) =>
                updateValue(i, {
                  enable: vv.target.checked,
                })
              }
            />
            <span>{getWeekName(i)}</span>
            <TimePicker.RangePicker
              disabled={!v.enable}
              value={[
                dayjs(`2021-01-01 ${v?.start}`),
                dayjs(`2021-01-01 ${v?.end}`),
              ]}
              onChange={(vv) => {
                if (vv?.length === 2) {
                  updateValue(i, {
                    start: vv[0]?.format('HH:mm'),
                    end: vv[1]?.format('HH:mm'),
                  });
                }
              }}
              format={'HH:mm'}
            />
          </Space>
        );
      })}
      <Button
        type="primary"
        loading={loading}
        onClick={() => {
          onUpdate({
            publicPoolTime: value,
            maxPeekCustomer: max,
            publicPoolWhiteListUserIdList,
          });
        }}
      >
        保存
      </Button>
    </Space>
  );
};

const tagPlusStyle: React.CSSProperties = {
  borderStyle: 'dashed',
};

const SensitiveWord = ({
  config,
  onUpdate,
  loading,
}: ConfigProps<CompanyConfig['sensitiveWordList']>) => {
  const [value, setValue] = useState<string>();
  const [showAdd, setShowAdd] = useState(false);
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (showAdd) {
      inputRef.current?.focus();
    }
  }, [showAdd]);

  const handleInputConfirm = () => {
    if (value && (!config?.length || config.indexOf(value) === -1)) {
      const result = [...(config || [])];
      result.push(value);
      onUpdate([...new Set(result)]);
    }
    setShowAdd(false);
    setValue(undefined);
  };

  return (
    <>
      <Space wrap>
        {config?.map((word) => (
          <Tag
            key={word}
            closable
            onClose={() => {
              onUpdate([...new Set(config.filter((v) => v !== word))]);
            }}
          >
            {word}
          </Tag>
        ))}
        {showAdd ? (
          <Input
            ref={inputRef}
            type="text"
            size="small"
            style={{ width: 78 }}
            value={value}
            onChange={(v) => setValue(v.target.value)}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        ) : (
          <Tag onClick={() => setShowAdd(true)} style={tagPlusStyle}>
            <PlusOutlined /> 新增
          </Tag>
        )}
      </Space>
    </>
  );
};

const JoinPublicPoolEditor = ({
  loading,
  onUpdate,
  config,
  enable,
  defaultDay,
}: Omit<ConfigProps<CompanyConfig['joinPublicPoolConfig']>, 'onUpdate'> & {
  defaultDay?: number;
  enable?: boolean;
  onUpdate: (
    v: Pick<
      CompanyConfig,
      | 'defaultJoinPublicPoolConfig'
      | 'joinPublicPoolConfig'
      | 'enableJoinPublicPool'
    >,
  ) => void;
}) => {
  const [value, setValue] = useState<
    Pick<
      CompanyConfig,
      | 'defaultJoinPublicPoolConfig'
      | 'enableJoinPublicPool'
      | 'joinPublicPoolConfig'
    >
  >({
    defaultJoinPublicPoolConfig: defaultDay,
    enableJoinPublicPool: enable,
    joinPublicPoolConfig: config,
  });
  const lableStyles = {
    width: '100px',
    display: 'inline-block',
    marginRight: '10px',
    textAlign: 'right',
  } as const;
  return (
    <Space direction="vertical">
      <Space>
        <span style={lableStyles}>抓取</span>
        <Switch
          checked={value.enableJoinPublicPool}
          checkedChildren="是"
          unCheckedChildren="否"
          onChange={(v) =>
            setValue((prev) => ({ ...prev, enableJoinPublicPool: v }))
          }
        />
      </Space>
      <Space>
        <span style={lableStyles}>默认</span>
        <InputNumber
          addonAfter="天"
          min={1}
          value={value.defaultJoinPublicPoolConfig}
          size="small"
          style={{ width: '100px' }}
          onChange={(v) =>
            setValue((prev) => {
              return {
                ...prev,
                defaultJoinPublicPoolConfig: v === null ? undefined : v,
              };
            })
          }
        />
      </Space>
      {Object.entries(customerStateMapping)
        .filter(
          ([key]) =>
            ![
              CustomerStateEnum.UNKNOWN,
              CustomerStateEnum.BLACK_LIST,
              CustomerStateEnum.APPROVAL,
            ].includes(key as CustomerStateEnum),
        )
        .map(([key, label]) => {
          return (
            <Space key={key}>
              <span style={lableStyles}>{label}</span>
              <InputNumber
                disabled={loading}
                style={{ width: '100px' }}
                size="small"
                addonAfter="天"
                value={
                  value?.joinPublicPoolConfig?.[key as CustomerStateEnum]?.day
                }
                min={1}
                onChange={(day) => {
                  setValue((prev) => {
                    const vvv = {} as Record<
                      CustomerStateEnum,
                      JoinPublicPoolConfig
                    >;
                    return {
                      ...prev,
                      joinPublicPoolConfig: {
                        ...(prev.joinPublicPoolConfig || vvv),
                        [key]: {
                          day: day,
                        },
                      },
                    };
                  });
                }}
              />
            </Space>
          );
        })}
      <Space>
        <span style={lableStyles}></span>
        <Button
          type="primary"
          onClick={() => {
            onUpdate({
              ...value,
            });
          }}
        >
          保存
        </Button>
      </Space>
    </Space>
  );
};

const HomeTopGroupConfig = ({
  config,
  loading,
  onUpdate,
}: ConfigProps<CompanyConfig['enableGroupHomeTop']>) => {
  return (
    <Switch
      checked={config}
      onChange={onUpdate}
      checkedChildren={'开启'}
      unCheckedChildren="关闭"
      loading={loading}
    />
  );
};

const SignPhotoRequired = ({
  config,
  loading,
  onUpdate,
}: ConfigProps<CompanyConfig['enableSignPhoto']>) => {
  return (
    <Switch
      checked={config}
      onChange={onUpdate}
      checkedChildren={'开启'}
      unCheckedChildren="关闭"
      loading={loading}
    />
  );
};

const DisableIndexRank = ({
  config,
  loading,
  onUpdate,
}: ConfigProps<CompanyConfig['disableIndexRank']>) => {
  return (
    <Switch
      checked={config}
      onChange={onUpdate}
      checkedChildren={'关闭'}
      unCheckedChildren="展示"
      loading={loading}
    />
  );
};

const InvalidCustomerEventCommentEditor = () => {
  const [value, setValue] = useState<InvalidCustomerEventDTO>();

  const { loading: getLoading, refresh } = useRequest(
    () => {
      return systemController.getInvalidCustomerEventComment();
    },
    {
      refreshDeps: [],
      onSuccess: (v) => {
        setValue(v);
      },
    },
  );

  const { loading: updateLoading, run: update } = useRequest(
    (v: InvalidCustomerEventDTO) => {
      return systemController.updateInvalidCustomerEventComment(v);
    },
    {
      manual: true,
      onError: (e) => {
        message.error(e.message || '更新失败');
      },
      onSuccess: () => {
        refresh();
      },
    },
  );

  const loading = getLoading || updateLoading;

  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={12}>
          <Input.TextArea
            rows={10}
            value={value?.comments?.join('\n')}
            onChange={(v) =>
              setValue((value) => ({
                ...value,
                comments: v.target.value.split('\n'),
              }))
            }
          />
        </Col>
        <Col span={12} style={{ paddingLeft: '10px' }}>
          <Space direction="vertical">
            <Space>
              <span>多少天以前的</span>
              <InputNumber
                value={value?.days}
                onChange={(days) =>
                  setValue((value) => ({ ...value, days: days || undefined }))
                }
              />
            </Space>
            <Switch
              checkedChildren={'开启'}
              unCheckedChildren="关闭"
              checked={value?.enabled}
              onChange={(enabled) =>
                setValue((value) => ({ ...value, enabled }))
              }
            />
          </Space>
        </Col>
        <Col span={24} style={{ marginTop: '6px' }}>
          <Button
            type="primary"
            loading={loading}
            onClick={() => update(value || {})}
          >
            保存
          </Button>
        </Col>
      </Row>
    </Spin>
  );
};

export default () => {
  const { config, id, refresh, hasPermission, currentUser } = useCompany();

  useEffect(() => {
    refresh();
  }, []);

  const { run: update, loading } = useRequest(
    (v: CompanyConfig) => {
      return companyController.updateCompanyConfig(id, {
        ...config,
        ...v,
      });
    },
    {
      manual: true,
      onSuccess: () => {
        message.success('更新成功');
        refresh();
      },
      onError: (e) => {
        message.error(e.message || '更新失败');
      },
    },
  );

  return (
    <Row>
      {hasPermission('page:system:company:ip') && (
        <Col span={12}>
          <Card
            title="IP 白名单"
            extra={'只有在列表里的 IP 可以登录系统'}
            style={{ margin: '6px' }}
          >
            <IPWhiteList
              config={config?.whiteIPList}
              loading={loading}
              {...config}
              onUpdate={(v) => {
                return update({
                  ...config,
                  ...v,
                });
              }}
            />
          </Card>
        </Col>
      )}

      {hasPermission('page:system:company:public-pool-pick') && (
        <Col span={12}>
          <Card title="公共池领取配置" style={{ margin: '6px' }}>
            <PublicPoolConfig
              config={config?.publicPoolTime}
              loading={loading}
              maxPeekCustomer={config?.maxPeekCustomer}
              whiteUserList={config?.publicPoolWhiteListUserIdList}
              onUpdate={(v) => {
                return update({
                  ...config,
                  ...v,
                });
              }}
            />
          </Card>
        </Col>
      )}
      {hasPermission('page:system:company:public-pool') && (
        <Col span={12}>
          <Card title="公共池抓取配置" style={{ margin: '6px' }}>
            <JoinPublicPoolEditor
              config={config?.joinPublicPoolConfig}
              defaultDay={config?.defaultJoinPublicPoolConfig}
              enable={config?.enableJoinPublicPool}
              loading={loading}
              onUpdate={(v) => {
                return update({
                  ...config,
                  ...v,
                });
              }}
            />
          </Card>
        </Col>
      )}

      {hasPermission('page:system:company:sensitive-word') && (
        <Col span={12}>
          <Card title="敏感词" style={{ margin: '6px' }}>
            <SensitiveWord
              config={config?.sensitiveWordList}
              loading={loading}
              onUpdate={(v) => {
                return update({
                  ...config,
                  sensitiveWordList: v,
                });
              }}
            />
          </Card>
        </Col>
      )}

      {hasPermission('page:system:company:logo') && (
        <Col span={12}>
          <Card title="公司 logo" style={{ margin: '6px' }}>
            <LogoConfig />
          </Card>
        </Col>
      )}
      {hasPermission('page:system:company:study_media') && (
        <Col span={12}>
          <Card title="学习园地" style={{ margin: '6px' }}>
            <StudyConfig
              config={config?.studyTime}
              loading={loading}
              onUpdate={(v) => {
                return update({
                  ...config,
                  ...v,
                });
              }}
            />
          </Card>
        </Col>
      )}
      {currentUser.isSuperAdmin && (
        <Col span={12}>
          <Card title="首页集团排行榜展示" style={{ margin: '6px' }}>
            <HomeTopGroupConfig
              config={config?.enableGroupHomeTop}
              loading={loading}
              onUpdate={(v) => {
                return update({
                  ...config,
                  enableGroupHomeTop: v,
                });
              }}
            />
          </Card>
        </Col>
      )}
      {currentUser.isSuperAdmin && (
        <Col span={12}>
          <Card title="签约必须上传照片" style={{ margin: '6px' }}>
            <SignPhotoRequired
              config={config?.enableSignPhoto}
              loading={loading}
              onUpdate={(v) => {
                return update({
                  ...config,
                  enableSignPhoto: v,
                });
              }}
            />
          </Card>
        </Col>
      )}
      {currentUser.isSuperAdmin && (
        <Col span={12}>
          <Card title="展示首页排行榜" style={{ margin: '6px' }}>
            <DisableIndexRank
              config={config?.disableIndexRank}
              loading={loading}
              onUpdate={(v) => {
                return update({
                  ...config,
                  disableIndexRank: v,
                });
              }}
            />
          </Card>
        </Col>
      )}
      {currentUser.isSuperAdmin && (
        <Col span={12}>
          <Card title="系统设置" style={{ margin: '6px' }}>
            <Row>
              <Col span={24}>
                <Space>
                  <>隐藏外部数据源</>
                  <Switch
                    checked={config?.hideOutsiderSource}
                    onChange={(v) => {
                      return update({
                        ...config,
                        hideOutsiderSource: v,
                      });
                    }}
                    checkedChildren={'关闭'}
                    unCheckedChildren="启用"
                    loading={loading}
                  />
                </Space>
              </Col>
              <Col span={24}>
                <Space>
                  <>隐藏报表</>
                  <Switch
                    checked={config?.hideReport}
                    onChange={(v) => {
                      return update({
                        ...config,
                        hideReport: v,
                      });
                    }}
                    checkedChildren={'关闭'}
                    unCheckedChildren="启用"
                    loading={loading}
                  />
                </Space>
              </Col>
            </Row>
          </Card>
        </Col>
      )}
      {currentUser.isSuperAdmin && (
        <Col span={12}>
          <Card title="无效备注配置" style={{ margin: '6px' }}>
            <InvalidCustomerEventCommentEditor />
          </Card>
        </Col>
      )}
    </Row>
  );
};
